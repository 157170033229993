<template>
    <div>
        <v-card style="border-radius: 14px;" class="mild-shadow" @click="handleClick">
            <v-img :src="business.banner_url" cover v-if="$vuetify.breakpoint.xs" class="rounded-t-lg">
            </v-img>
            <v-img :src="business.banner_url" cover max-height="300" v-else class="rounded-t-lg">
            </v-img>
        </v-card>
    </div>
</template>

<script>

export default {
    name: 'AffiliateCard',
    props: ['business'],
    data() {
        return {

        }
    },
    components: {

    },
    mounted() {

    },
    watch: {

    },
    computed: {

    },

    methods: {

        async handleClick() {

            let bxid = this.business.xid;
            let action = 'click';
            await this.$store.dispatch('logAffiliatedBusinessAnalytics', { bxid, action });

            window.open(this.business.link_url, '_blank');

        },

    }

}
</script>