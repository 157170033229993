<template>
      <div :class="{'pa-8': $route.path === '/adopter-questionnaire'}">
         
            <v-row justify="center" no-gutters class="mt-4 mb-8">
              <v-col cols="12" class="justify-center">
                <v-row no-gutters>
                  <v-col class="px-2">
                    <v-progress-linear color="primary" :value="progress" rounded height="8"></v-progress-linear>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-col cols="12" v-show="step === 1">
              <div class="primary--text text-subtitle-2 font-weight-600">Where do you live?</div>
              <v-select dense outlined flat solo hide-details v-model="residence_type"
                        :items="['House', 'Apartment', 'Condo', 'Other']" label="Select Residence Type"
                        class="mt-2" @change="validateStep"></v-select>
            </v-col>

            <v-col cols="12" v-show="step === 2">
              <div class="primary--text text-subtitle-2 font-weight-600">Do you own or rent your home?</div>
              <v-radio-group v-model="owns_home" class="mt-2" row hide-details>
                <v-radio label="Own" :value="1"></v-radio>
                <v-radio label="Rented" :value="0"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col cols="12" v-show="owns_home === 0 && step === 2">
                <div class="primary--text text-subtitle-2 font-weight-600">Do you have permission from your landlord to have a pet?</div>
                <v-radio-group v-model="has_landlord_permission" class="mt-2" row hide-details>
                  <v-radio label="Yes" :value="1"></v-radio>
                  <v-radio label="No" :value="0"></v-radio>
                </v-radio-group>
            </v-col>

            <v-col cols="12" v-show="step === 3">
              <div class="primary--text text-subtitle-2 font-weight-600">Have you ever owned a pet before?</div>
              <v-radio-group v-model="has_previous_pet_experience" class="mt-2" row hide-details>
                <v-radio label="Yes" :value="1"></v-radio>
                <v-radio label="No" :value="0"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" v-show="has_previous_pet_experience === 1 && step === 3">
              <div class="primary--text text-subtitle-2 font-weight-600">What type of pet(s) did you owned and for how long?</div>
              <v-textarea class="mt-2" dense outlined hide-details auto-grow placeholder=""
                          v-model="previous_pet_details"></v-textarea>
            </v-col>

            <v-col cols="12" v-show="step === 4">
              <div class="primary--text text-subtitle-2 font-weight-600">How active is your lifestyle? Do
                you enjoy outdoor activities?</div>
              <v-textarea class="mt-2" dense outlined hide-details auto-grow row-height="30" rows="5"
                          placeholder="" v-model="activity_level"></v-textarea>
            </v-col>

            <v-col cols="12" v-show="step === 5">
              <div class="primary--text text-subtitle-2 font-weight-600">How much time are you willing to
                dedicate to caring for a pet each day?</div>
                <v-textarea class="mt-2" dense outlined hide-details auto-grow row-height="30" rows="5"
                          placeholder="" v-model="time_commitment"></v-textarea>
            </v-col>
            <v-col cols="12" v-show="step === 6">
              <div class="primary--text text-subtitle-2 font-weight-600">What are you looking for in a
                pet?</div>
              <v-textarea class="mt-2" dense outlined hide-details auto-grow row-height="30" rows="5"
                          placeholder="Companionship/Exercise partner/Family pet"
                          v-model="expectations"></v-textarea>
            </v-col>

            <v-col cols="12" v-show="step === 7">
              <div class="primary--text text-subtitle-2 font-weight-600">Are you willing to invest time
                and resources in training your pet?</div>
              <v-radio-group v-model="willing_to_train" class="mt-2" row hide-details>
                <v-radio label="Yes" :value="1"></v-radio>
                <v-radio label="No" :value="0"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col cols="12" v-show="step === 8">
              <div class="primary--text text-subtitle-2 font-weight-600">How much are you willing to spend
                on pet care expenses like food, vet visits, grooming, etc.?</div>
              <v-text-field class="mt-2" v-model="pet_care_budget" dense outlined hide-details flat type="number"
                            placeholder="0" @blur="trimBudget"></v-text-field>

            </v-col>

            <v-col cols="12" v-show="step === 9">
              <div class="primary--text text-subtitle-2 font-weight-600">Do you have a fenced yard?</div>
              <v-radio-group v-model="has_fenced_yard" class="mt-2" row hide-details>
                <v-radio label="Yes" :value="1"></v-radio>
                <v-radio label="No" :value="0"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col cols="12" v-show="step === 10">
              <div class="primary--text text-subtitle-2 font-weight-600">How do you plan to exercise your
                pet?</div>
              <v-textarea class="mt-2" dense outlined hide-details auto-grow row-height="30" rows="5"
                          placeholder="" v-model="exercise_plan"></v-textarea>
            </v-col>

            <v-col cols="12" v-show="step === 11">
              <div class="primary--text text-subtitle-2 font-weight-600">Do you have other pets in the
                household?</div>
              <v-radio-group v-model="has_other_pets" class="mt-2" row hide-details>
                <v-radio label="Yes" :value="1"></v-radio>
                <v-radio label="No" :value="0"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col cols="12" v-show="step === 11 && has_other_pets === 1">
              <div class="primary--text text-subtitle-2 font-weight-600">Please describe them
                (Species/Breed/Age/Temperament)</div>
              <v-textarea class="mt-2" dense outlined hide-details auto-grow row-height="30" rows="5"
                          placeholder="" v-model="other_pet_details"></v-textarea>
            </v-col>

            <v-col cols="12" v-show="step === 12">
              <div class="primary--text text-subtitle-2 font-weight-600">Do you have any allergies?</div>
              <v-radio-group v-model="has_allergies" class="mt-2" row hide-details>
                <v-radio label="Yes" :value="1"></v-radio>
                <v-radio label="No" :value="0"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col cols="12" v-show="step === 12 && has_allergies === 1">
              <div class="primary--text text-subtitle-2 font-weight-600">Please specify</div>
              <v-textarea class="mt-2" dense outlined hide-details auto-grow row-height="30" rows="5"
                          placeholder="" v-model="allergies"></v-textarea>
            </v-col>

            <v-col cols="12" v-show="step === 13">
              <div class="primary--text text-subtitle-2 font-weight-600">Who will be the primary caregiver
                for the pet?</div>
              <v-text-field class="mt-2" v-model="primary_caregiver" dense outlined hide-details flat></v-text-field>
            </v-col>

            <v-col cols="12" v-show="step === 14">
              <div class="primary--text text-subtitle-2 font-weight-600">What are your plans if you go on
                vacation or are unable to care for the pet?</div>
              <v-textarea class="mt-2" dense outlined hide-details auto-grow row-height="30" rows="5"
                          placeholder="" v-model="vacation_care_plan"></v-textarea>
            </v-col>

            <v-col cols="12" v-show="step === 15">
              <div class="primary--text text-subtitle-2 font-weight-600">Is there anything else you would
                like to share about yourself or your interest in getting a pet?</div>
              <v-textarea class="mt-2" dense outlined hide-details auto-grow row-height="30" rows="5"
                          placeholder="" v-model="additional_information"></v-textarea>
            </v-col>

            <v-col cols="12" v-show="step === 16">
              <div class="primary--text text-subtitle-2 font-weight-600">How did you hear about Petmeetly?
              </div>
              <v-text-field class="mt-2" v-model="referral_source" dense outlined hide-details flat></v-text-field>
            </v-col>

            <v-card-actions class="mt-4">

                <v-btn v-if="showPrevButton" color="gray" depressed @click="prevStep">Back</v-btn>
              <v-spacer></v-spacer>

              <v-btn v-if="!isLastStep" color="primary" @click="nextStep" :disabled="!isStepValid">Next</v-btn>
                <v-btn v-if="isLastStep" color="primary" @click="submit(1)" :disabled="!isStepValid">Submit</v-btn>

          </v-card-actions>

         

      <v-snackbar v-model="snackbar">
          {{ snackbar_text }}
          <template v-slot:action="{ attrs }">
              <v-btn color="secondary" icon v-bind="attrs" @click="snackbar = false">
                  <v-icon>mdi-close</v-icon>
              </v-btn>
          </template>
      </v-snackbar>

      <v-overlay :value="dataLoading">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      </div>
</template>

<script>
export default {
    name: 'AdopterQuestionnaire',

    computed: {
      showPrevButton() {
        return this.stepsArray.indexOf(this.step) > 0;
      },
      isLastStep() {
        return this.stepsArray.indexOf(this.step) === this.stepsArray.length - 1;
      },
      progress() {
        // Calculate progress as a percentage
        let currentIndex = this.stepsArray.indexOf(this.step);
        return ((currentIndex + 1) / this.stepsArray.length) * 100;
      },
      isStepValid() {
        // Check if the current step's fields are valid
        switch (this.step) {
          case 1:
            return this.residence_type !== '';
          case 2:
            return this.owns_home === 0 && this.step === 2 && this.has_landlord_permission !== ''
                || this.owns_home === 1;
          case 3:
            return this.has_previous_pet_experience === 1 && this.step === 3 && this.previous_pet_details.trim() !== ''
                || this.has_previous_pet_experience === 0;
          case 4:
            return this.activity_level.trim() !== '';
          case 5:
            return this.time_commitment.trim() !== '';
          case 6:
            return this.expectations.trim() !== '';
          case 7:
            return this.willing_to_train !== '';
          case 8:
            return this.pet_care_budget !== null && this.pet_care_budget !== undefined && this.pet_care_budget !== '';
          case 9:
            return this.has_fenced_yard !== '';
          case 10:
            return this.exercise_plan.trim() !== '';
          case 11:
            return this.step === 11 && this.has_other_pets === 1 && this.other_pet_details.trim() !== ''
                || this.has_other_pets === 0;
          case 12:
            return this.step === 12 && this.has_allergies === 1 && this.allergies.trim() !== ''
                || this.has_allergies === 0;
          case 13:
            return this.primary_caregiver.trim() !== '';
          case 14:
            return this.vacation_care_plan.trim() !== '';
          case 15:
            return this.additional_information.trim() !== '';
          case 16:
            return this.referral_source !== '';
          default:
            return false;
        }
      },
    },
    mounted() {
        if(this.$store.getters.userDetails.category !== 'adopter' && this.$store.getters.userDetails.category !== 'buyer') {
            this.$router.replace('home');
        } else {
            this.getAdopterQuestionnaire();
        }
    },

    data() {
        return {
            step: 1,
            stepsArray: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],

            submitting: false,
            dataLoading: false,
            snackbar: false,
            snackbar_text: '',

            residence_type: '',
            owns_home: '',
            has_landlord_permission: '',
            has_previous_pet_experience: '',
            previous_pet_details: '',
            activity_level: '',
            time_commitment: '',
            expectations: '',
            willing_to_train: '',
            pet_care_budget: '',
            has_fenced_yard: '',
            exercise_plan: '',
            has_other_pets: '',
            other_pet_details: '',
            has_allergies: '',
            allergies: '',
            primary_caregiver: '',
            vacation_care_plan: '',
            additional_information: '',
            referral_source: '',

        }
    },

    methods: {
        validateStep() {
          this.isStepValid;
        },
        closeDialog() {
          this.$emit('close-dialog');
        },
        nextStep() {
          let currentIndex = this.stepsArray.indexOf(this.step);
          if (currentIndex !== -1 && currentIndex < this.stepsArray.length - 1) {
            this.submit(0);
            this.step = this.stepsArray[currentIndex + 1];
          }
        },
        prevStep() {
          let currentIndex = this.stepsArray.indexOf(this.step);
          if (currentIndex > 0) {
            this.step = this.stepsArray[currentIndex - 1];
          }
        },

        trimBudget() {
            const maxBudget = 999999.99;
            if (this.pet_care_budget > maxBudget) {
                this.pet_care_budget = maxBudget; // Trim to the max value
            }
        },

        getAdopterQuestionnaire() {
            this.dataLoading = true;
            this.$store.dispatch('getAdopterQuestionnaire').then((resp) => {

                this.dataLoading = false;

                this.residence_type = resp.data.residence_type == null ? '' : resp.data.residence_type;
                this.owns_home = resp.data.owns_home == null ? '' : resp.data.owns_home;
                this.has_landlord_permission = resp.data.has_landlord_permission == null ? '' : resp.data.has_landlord_permission;
                this.has_previous_pet_experience = resp.data.has_previous_pet_experience == null ? '' : resp.data.has_previous_pet_experience;
                this.previous_pet_details = resp.data.previous_pet_details == null ? '' : resp.data.previous_pet_details;
                this.activity_level = resp.data.activity_level == null ? '' : resp.data.activity_level;
                this.time_commitment = resp.data.time_commitment == null ? '' : resp.data.time_commitment;
                this.expectations = resp.data.expectations == null ? '' : resp.data.expectations;
                this.willing_to_train = resp.data.willing_to_train == null ? '' : resp.data.willing_to_train;
                this.pet_care_budget = resp.data.pet_care_budget == null ? '' : resp.data.pet_care_budget;
                this.has_fenced_yard = resp.data.has_fenced_yard == null ? '' : resp.data.has_fenced_yard;
                this.exercise_plan = resp.data.exercise_plan == null ? '' : resp.data.exercise_plan;
                this.has_other_pets = resp.data.has_other_pets == null ? '' : resp.data.has_other_pets;
                this.other_pet_details = resp.data.other_pet_details == null ? '' : resp.data.other_pet_details;
                this.has_allergies = resp.data.has_allergies == null ? '' : resp.data.has_allergies;
                this.allergies = resp.data.allergies == null ? '' : resp.data.allergies;
                this.primary_caregiver = resp.data.primary_caregiver == null ? '' : resp.data.primary_caregiver;
                this.vacation_care_plan = resp.data.vacation_care_plan == null ? '' : resp.data.vacation_care_plan;
                this.additional_information = resp.data.additional_information == null ? '' : resp.data.additional_information;
                this.referral_source = resp.data.referral_source == null ? '' : resp.data.referral_source;

            })
                .catch(err => {
                    this.dataLoading = false;
                    this.snackbar_text = "Oops, Unable to get the details";
                    this.snackbar = true;
                });
        },

        submit(final) {

            this.submitting = true;

            let formData = new FormData();

            formData.append('residence_type', this.residence_type);
            formData.append('owns_home', this.owns_home);
            formData.append('has_landlord_permission', this.has_landlord_permission);
            formData.append('has_previous_pet_experience', this.has_previous_pet_experience);
            formData.append('previous_pet_details', this.previous_pet_details);
            formData.append('activity_level', this.activity_level);
            formData.append('time_commitment', this.time_commitment);
            formData.append('expectations', this.expectations);
            formData.append('willing_to_train', this.willing_to_train);
            formData.append('pet_care_budget', this.pet_care_budget);
            formData.append('has_fenced_yard', this.has_fenced_yard);
            formData.append('exercise_plan', this.exercise_plan);
            formData.append('has_other_pets', this.has_other_pets);
            formData.append('other_pet_details', this.other_pet_details);
            formData.append('has_allergies', this.has_allergies);
            formData.append('allergies', this.allergies);
            formData.append('primary_caregiver', this.primary_caregiver);
            formData.append('vacation_care_plan', this.vacation_care_plan);
            formData.append('additional_information', this.additional_information);
            formData.append('referral_source', this.referral_source);
            formData.append('step', this.step);

            this.$store.dispatch('submitAdopterQuestionnaire', {
                formData
            })
                .then((resp) => {
                    this.submitting = false;
                    if(final)
                    {
                      this.closeDialog();
                      // this.$router.replace('home');

                      if (this.$route.path === '/adopter-questionnaire') {
                        this.$router.replace('home');
                        }
                    }
                })
                .catch(err => {
                    this.submitting = false;
                    this.snackbar_text = "Oops, there is an error while submitting. Please retry or contact support";
                    this.snackbar = true;
                });
        },
    },

}
</script>
