<template>
    <div class="editpet">
        <v-app-bar app flat color="white">

            <v-btn icon :to="'/home'">
                <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-toolbar-title>Edit Your Pet</v-toolbar-title>

        </v-app-bar>

        <v-row justify="center" class="ma-0 mb-16 pb-8">
            <v-col cols="12" sm="6" md="5" lg="4" class="justify-center">

                <v-row justify="center" align="center" class="pt-6">

                    <v-col cols="6" class="px-6 text-center">

                        <v-img class="text-center align-end" v-if="petAvatar" :src="petAvatar.photo" cover style="border-radius:60px;" max-width="120"
                            aspect-ratio="1">
                            <v-btn icon dark @click="deletePic(petAvatar.xid)" style="background-color:rgba(0,0,0,0.30); top:-55px; ">
                                <v-icon>
                                    mdi-close
                                </v-icon>
                            </v-btn>
                            <v-btn class="mb-0 px-6" dark color="black" small @click="launchAvatarPicker">
                              change
                            </v-btn>
                        </v-img>

                        <v-card v-else flat
                            style="border-radius:60px; border: 2px dashed #adb5bd; width: 120px; height: 120px;"
                            @click="launchAvatarPicker">
                            <v-icon class="pa-8" color="primary" size="36">mdi-image-plus</v-icon>
                        </v-card>

                        <input type="file" ref="avatarpic" name="avatarpic" @change="loadAvatar($event)"
                            style="display:none" accept="image/png, image/jpg, image/jpeg">

                        <v-dialog v-model="avatarDialog" width="500" :fullscreen="$vuetify.breakpoint.mobile">
                            <v-card color="black">
                                <v-toolbar flat dark color="black">
                                    <v-btn icon @click="avatarDialog = false" color="grey">
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn icon dark @click="saveAvatar" :loading="asubmitting" :disabled="asubmitting">
                                        <v-icon>mdi-check</v-icon>
                                    </v-btn>
                                </v-toolbar>
                                <v-card-text class="pa-0">
                                    <cropper ref="acropper" :src="avatarFile" :canvas="{
                                                  height: 600,
                                                  width: 800
                                                }" :stencil-props="{
                                                    aspectRatio: 8/6,
                                                    movable: true,
                                                    scalable: true,
                                                }" image-restriction="stencil" />

                                </v-card-text>
                            </v-card>
                        </v-dialog>

                    </v-col>

                </v-row>

                <v-row>
                    <v-col cols="12">
                        <div class="primary--text text-subtitle-2 font-weight-600">Type of pet</div>
                        <v-select dense outlined flat solo hide-details v-model="pet_type"
                            :items="this.$store.getters.petTypes" item-text="type" item-value="xid" label="Select"
                            class="mt-1" @change="breed1=''; breed2=''"></v-select>
                        <!-- <v-radio-group v-model="pet_type" class="mt-2">
                        <v-radio :label="item.type" :value="item.xid" v-for="(item,index) in this.$store.getters.petTypes" :key="index" @change="breed1=''; breed2=''"></v-radio>
                    </v-radio-group> -->
                    </v-col>
                    <v-col cols="12">
                        <div class="primary--text text-subtitle-2 font-weight-600">Your pet need</div>
                        <v-select dense outlined flat solo hide-details v-model="purpose" :items="purpose_arr"
                            label="Select Purpose" class="mt-1"></v-select>
                        <!-- <v-radio-group v-model="purpose" class="mt-2">
                        <v-radio label="Lover (Mating Partner)" value="lover"></v-radio>
                        <v-radio label="Friend (Playmate)" value="friend"></v-radio>
                        <v-radio label="Adopter" value="adopter"></v-radio>
                        <v-radio label="Buyer" value="buyer"></v-radio>
                    </v-radio-group> -->
                    </v-col>
                    <v-col cols="12">
                        <div class="primary--text text-subtitle-2 font-weight-600">Name</div>
                        <v-text-field outlined dense hide-details v-model="name" class="mt-1"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <div class="primary--text text-subtitle-2 font-weight-600">Sex</div>
                        <v-radio-group v-model="gender" class="mt-0" row hide-details>
                            <v-radio label="Male" value="M"></v-radio>
                            <v-radio label="Female" value="F"></v-radio>
                        </v-radio-group>
                    </v-col>
                </v-row>


                <v-sheet outlined class="pa-4 my-8 rounded-lg" style="background-color: #fafafa;">

                    <v-row>

                        <v-col cols="12">
                            <div class="primary--text text-subtitle-2 font-weight-600">Breed</div>
                            <v-radio-group v-model="breed_type" class="mt-0" row hide-details>
                                <v-radio label="Purebred" value="purebred"></v-radio>
                                <v-radio label="Crossbred" value="crossbred"></v-radio>
                            </v-radio-group>

                            <!-- <v-checkbox v-model="purebred" hide-details label="Purebred"></v-checkbox>

                    <v-checkbox v-model="crossbred" label="Crossbred(Mixed Breed)" class="mt-0"></v-checkbox> -->

                            <v-autocomplete class="mt-4" v-model="breed1" hide-no-data :filter="filterObject"
                                :items="typeBreeds" item-text="breed" item-value="xid" label="Primary Breed"
                                return-object dense outlined flat solo hide-details>

                                <template v-slot:item="data">
                                    <v-list-item-content>
                                        <v-list-item-title v-html="data.item.breed"></v-list-item-title>
                                        <v-list-item-subtitle v-html="data.item.description"></v-list-item-subtitle>
                                    </v-list-item-content>
                                </template>

                            </v-autocomplete>

                            <v-autocomplete class="mt-6" v-show="crossbred && breed1" v-model="breed2"
                                :items="typeBreeds" item-text="breed" item-value="xid" label="Secondary Breed"
                                return-object dense outlined flat solo hide-details>

                                <template v-slot:item="data">
                                    <v-list-item-content>
                                        <v-list-item-title v-html="data.item.breed"></v-list-item-title>
                                        <v-list-item-subtitle v-html="data.item.description"></v-list-item-subtitle>
                                    </v-list-item-content>
                                </template>

                            </v-autocomplete>
                        </v-col>
                    </v-row>
                </v-sheet>
                <v-row>

                    <v-col cols="12">
                        <div class="primary--text text-subtitle-2 font-weight-600 mb-1">Age</div>
                        <v-row>
                            <v-col cols="6">
                                <v-text-field v-model="age_years" dense outlined type="number" placeholder="0"
                                    :suffix="age_years > 1 ? 'years' : 'year'" :rules="yearRules"
                                    hide-details></v-text-field>
                            </v-col>

                            <v-col cols="6">
                                <v-text-field v-model="age_months" dense outlined type="number" placeholder="0"
                                    :suffix="age_months > 1 ? 'months' : 'month'" :rules="monthRules"
                                    hide-details></v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <div class="primary--text text-subtitle-2 font-weight-600 mb-1">Weight</div>
                        <v-row>
                            <v-col cols="6">
                                <v-text-field v-model="weight_number" dense outlined flat type="number" placeholder="0"
                                    :rules="weightRules" hide-details></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-select v-model="weight_unit" dense outlined flat hide-details
                                    :items="['lbs', 'kgs']">
                                </v-select>
                            </v-col>
                        </v-row>
                    </v-col>


                    <v-col cols="12">
                        <div class="primary--text text-subtitle-2 font-weight-600">Size</div>
                        <v-select dense outlined flat hide-details v-model="size" :items="this.$store.getters.petSizes"
                            item-text="size" item-value="xid" placeholder="Select" class="mt-1"></v-select>
                        <!-- <v-radio-group v-model="pet_type" class="mt-2">
                        <v-radio :label="item.type" :value="item.xid" v-for="(item,index) in this.$store.getters.petTypes" :key="index" @change="breed1=''; breed2=''"></v-radio>
                    </v-radio-group> -->
                    </v-col>

                </v-row>

                <v-sheet outlined class="pa-4 my-8 rounded-lg" style="background-color: #fafafa;">

                    <v-row>



                        <v-col cols="6">

                            <div class="primary--text text-subtitle-2 font-weight-600">Pedigree Certified</div>
                            <v-select dense outlined flat solo hide-details v-model="pedigree_certified"
                                :items="yes_no_arr" label="" class="mt-1"></v-select>

                        </v-col>
                        <v-col cols="6">

                            <div class="primary--text text-subtitle-2 font-weight-600">DNA Tested</div>
                            <v-select dense outlined flat solo hide-details v-model="dna_tested" :items="yes_no_arr"
                                label="" class="mt-1"></v-select>
                        </v-col>
                        <v-col cols="6">

                            <div class="primary--text text-subtitle-2 font-weight-600">Vaccinated</div>
                            <v-select dense outlined flat solo hide-details v-model="vaccinated" :items="yes_no_arr"
                                label="" class="mt-1"></v-select>
                        </v-col>



                        <v-col cols="6" v-if="purpose != 'lover'">
                            <div class="primary--text text-subtitle-2 font-weight-600">Neutered</div>
                            <v-select dense outlined flat solo hide-details v-model="neutered" :items="yes_no_arr"
                                label="" class="mt-1"></v-select>
                        </v-col>



                        <v-col cols="6" v-if="purpose == 'lover'">
                            <div class="primary--text text-subtitle-2 font-weight-600">Breeding for the first time</div>
                            <v-select dense outlined flat solo hide-details v-model="mating_first_time"
                                :items="yes_no_arr" label="" class="mt-1"></v-select>
                        </v-col>

                        <v-col cols="6" v-if="purpose == 'lover'">
                            <div class="primary--text text-subtitle-2 font-weight-600">How many times have you bred your pet?</div>
                            <v-text-field class="mt-1" v-model="breeding_count" outlined dense solo flat hide-details  type="number"
                                placeholder="0"></v-text-field>

                        </v-col>


                    </v-row>
                </v-sheet>
                <v-row>
                    <v-col cols="12">
                        <div class="primary--text text-subtitle-2 font-weight-600">Write about your pet</div>
                        <v-textarea class="mt-1" dense outlined hide-details auto-grow row-height="30" rows="5"
                            placeholder="Your pet's temperament, character and what are you looking for"
                            v-model="description"></v-textarea>

                    </v-col>

                    <v-col cols="12">
                        <div class="primary--text text-subtitle-2 font-weight-600">Instagram handle (optional)</div>
                        <v-text-field class="mt-1" outlined dense hide-details v-model="insta_handle"
                            placeholder="your pet's instagram handle" prefix="@"></v-text-field>

                    </v-col>



                    <v-col cols="12" v-if="purpose=='buyer'">
                        <div class="mb-1 primary--text text-subtitle-2 font-weight-600">Price</div>
                        <v-row>
                            <v-col cols="6">
                                <v-text-field v-model="price" dense outlined hide-details flat type="number"
                                    placeholder="0" :rules="priceRules"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-select v-model="price_ccy" dense outlined flat hide-details
                                    :items="['$ USD', '€ EUR', '$ CAD', '$ AUD', '£ GBP', '₹ INR']">
                                </v-select>
                            </v-col>
                        </v-row>
                    </v-col>

                    <v-col cols="12" v-if="purpose=='adopter'">
                        <div class="mb-1 primary--text text-subtitle-2 font-weight-600">Adoption Fee</div>
                        <v-row>
                            <v-col cols="6">
                                <v-text-field v-model="adoption_fees" dense outlined hide-details flat type="number"
                                    placeholder="0" :rules="priceRules"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-select v-model="adoption_fees_ccy" dense outlined solo flat hide-details
                                    :items="['$ USD', '€ EUR', '$ CAD', '$ AUD', '£ GBP', '₹ INR']">
                                </v-select>
                            </v-col>
                        </v-row>
                    </v-col>

                    <v-col cols="12" v-if="purpose == 'lover' && gender == 'M'">
                        <div class="mb-1 primary--text text-subtitle-2 font-weight-600">Stud Fee</div>
                        <v-row>
                            <v-col cols="6">
                                <v-text-field v-model="stud_fees" dense outlined hide-details flat type="number"
                                    placeholder="0" :rules="priceRules"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-select v-model="stud_fees_ccy" dense outlined solo flat hide-details
                                    :items="['$ USD', '€ EUR', '$ CAD', '$ AUD', '£ GBP', '₹ INR']">
                                </v-select>
                            </v-col>
                        </v-row>
                    </v-col>




                </v-row>
                <v-sheet outlined class="pa-4 my-8 rounded-lg" style="background-color: #fafafa;">

                    <v-row>

                        <v-col cols="6">

                            <div class="primary--text text-subtitle-2 font-weight-600">Potty Trained?</div>
                            <v-select dense outlined flat solo hide-details v-model="potty_trained" :items="yes_no_arr"
                                label="" class="mt-1"></v-select>

                        </v-col>

                        <v-col cols="6">

                            <div class="primary--text text-subtitle-2 font-weight-600">Good with Kids?</div>
                            <v-select dense outlined flat solo hide-details v-model="good_with_kids"
                                :items="yes_no_dk_arr" label="" class="mt-1"></v-select>

                        </v-col>

                        <v-col cols="6">
                            <div class="primary--text text-subtitle-2 font-weight-600">Good with Cats?</div>
                            <v-select dense outlined flat solo hide-details v-model="good_with_cats"
                                :items="yes_no_dk_arr" label="" class="mt-1"></v-select>

                        </v-col>

                        <v-col cols="6">

                            <div class="primary--text text-subtitle-2 font-weight-600">Good with Dogs?</div>
                            <v-select dense outlined flat solo hide-details v-model="good_with_other_dogs"
                                :items="yes_no_dk_arr" label="" class="mt-1"></v-select>

                        </v-col>

                    </v-row>
                </v-sheet>

                <v-sheet outlined class="pa-4 my-8 rounded-lg" style="background-color: #fafafa;">

                    <v-row>

                        <v-col cols="6">

                            <div class="primary--text text-subtitle-2 font-weight-600">Microchipped</div>
                            <v-select dense outlined flat solo hide-details v-model="is_microchipped" :items="yes_no_arr"
                                label="" class="mt-1"></v-select>

                        </v-col>

                        <v-col cols="6">

                            <div class="primary--text text-subtitle-2 font-weight-600">House-trained?</div>
                            <v-select dense outlined flat solo hide-details v-model="is_house_trained"
                                :items="yes_no_arr" label="" class="mt-1"></v-select>

                        </v-col>

                        <v-col cols="12" v-if="purpose == 'lover'">
                            <div class="primary--text text-subtitle-2 font-weight-600">Are you familiar with the breed standard and any
                                genetic health issues associated with this breed?</div>
                            <v-select dense outlined flat solo hide-details v-model="familiar_with_breed_standard"
                                :items="yes_no_arr" label="" class="mt-1"></v-select>

                        </v-col>

                        <v-col cols="12" v-if="purpose == 'lover'">

                            <div class="primary--text text-subtitle-2 font-weight-600">Have you consulted with a veterinarian about
                                breeding your pet?</div>
                            <v-select dense outlined flat solo hide-details v-model="consulted_veterinarian"
                                :items="yes_no_arr" label="" class="mt-1"></v-select>

                        </v-col>

                        <v-col cols="12" v-if="purpose == 'lover'">

                            <div class="primary--text text-subtitle-2 font-weight-600">Are you willing to sign a breeding contract with
                                the other pet owner?</div>
                            <v-select dense outlined flat solo hide-details v-model="willing_to_sign_contract"
                                :items="yes_no_arr" label="" class="mt-1"></v-select>

                            </v-col>

                            <v-col cols="12" v-if="purpose == 'buyer' || purpose == 'adopter'">

                            <div class="primary--text text-subtitle-2 font-weight-600">Are you willing to meet the buyer halfway for the
                                transaction?</div>
                            <v-select dense outlined flat solo hide-details v-model="willing_to_meet"
                                :items="yes_no_arr" label="" class="mt-1"></v-select>

                            </v-col>

                    </v-row>
                </v-sheet>

                <v-row>

                    <v-col cols="12" v-if="purpose == 'lover' || purpose == 'friend'">
                        <div class="primary--text text-subtitle-2 font-weight-600">Willingness to travel</div>
                        <v-textarea class="mt-1" dense outlined auto-grow row-height="30" rows="4" hide-details
                            placeholder="How far can you travel for meetings?"
                            v-model="willingness_to_travel"></v-textarea>
                    </v-col>

                    <v-col cols="12" v-if="purpose == 'lover'">
                        <div class="primary--text text-subtitle-2 font-weight-600">Breeding Terms</div>
                        <v-textarea class="mt-1" dense outlined auto-grow row-height="30" rows="4" hide-details
                            placeholder="Describe any special conditions you require for breeding."
                            v-model="breeding_terms"></v-textarea>
                    </v-col>

                    <v-col cols="12" v-if="purpose == 'lover'">
                        <div class="primary--text text-subtitle-2 font-weight-600">Accommodation Responsibility</div>
                        <v-textarea class="mt-1" dense outlined auto-grow row-height="30" rows="4" hide-details
                            placeholder="Will you arrange accommodation, or should the other party?"
                            v-model="accommodation_responsibility"></v-textarea>
                    </v-col>

                    <v-col cols="12" v-if="purpose == 'lover' || purpose == 'friend'">
                        <div class="primary--text text-subtitle-2 font-weight-600">Does the pet have any known health issues? If so,
                            please describe.</div>
                        <v-textarea class="mt-1" dense outlined auto-grow row-height="30" rows="4" hide-details
                            placeholder=""
                            v-model="health_issues"></v-textarea>
                    </v-col>

                    <v-col cols="12" v-if="purpose == 'lover'">
                        <div class="primary--text text-subtitle-2 font-weight-600">Do you have a plan for caring for and placing the
                            puppies/kittens?</div>
                        <v-textarea class="mt-1" dense outlined auto-grow row-height="30" rows="4" hide-details
                            placeholder=""
                            v-model="care_and_placement_plan"></v-textarea>
                    </v-col>

                    <v-col cols="12" v-if="purpose == 'lover'">
                        <div class="primary--text text-subtitle-2 font-weight-600">Has your pet been tested for any breed-specific
                            health conditions or genetic disorders? If yes, please provide details of the tests and
                            results.</div>
                        <v-textarea class="mt-1" dense outlined auto-grow row-height="30" rows="4" hide-details
                            placeholder=""
                            v-model="health_test_results"></v-textarea>
                    </v-col>

                    

                    <v-col cols="12" v-if="purpose == 'buyer' || purpose == 'adopter'">
                        <div class="primary--text text-subtitle-2 font-weight-600">Where did you get the pet?</div>
                        <v-textarea class="mt-1" dense outlined auto-grow row-height="30" rows="4" hide-details
                            placeholder=""
                            v-model="pet_source"></v-textarea>
                    </v-col>

                    <v-col cols="12" v-if="purpose == 'buyer' || purpose == 'adopter'">
                        <div class="primary--text text-subtitle-2 font-weight-600">Describe the pet's current living environment –
                            indoor/outdoor, type of housing, etc.</div>
                        <v-textarea class="mt-1" dense outlined auto-grow row-height="30" rows="4" hide-details
                            placeholder=""
                            v-model="living_situation"></v-textarea>
                    </v-col>

                    <v-col cols="12" v-if="purpose == 'buyer' || purpose == 'adopter'">
                        <div class="primary--text text-subtitle-2 font-weight-600">What type of food do you feed the pet? How much
                            exercise does it get daily?</div>
                        <v-textarea class="mt-1" dense outlined auto-grow row-height="30" rows="4" hide-details
                            placeholder=""
                            v-model="diet_and_exercise"></v-textarea>
                    </v-col>

                    <v-col cols="12" v-if="purpose == 'buyer' || purpose == 'adopter'">
                        <div class="primary--text text-subtitle-2 font-weight-600">Be as detailed as possible about why you are
                            rehoming the pet</div>
                        <v-textarea class="mt-1" dense outlined auto-grow row-height="30" rows="4" hide-details
                            placeholder=""
                            v-model="rehoming_reason"></v-textarea>
                    </v-col>

                    <v-col cols="12" v-if="purpose == 'buyer' || purpose == 'adopter'">
                        <div class="primary--text text-subtitle-2 font-weight-600">Included with Sale: (Food, toys, crate, leash,
                            etc.)</div>
                        <v-textarea class="mt-1" dense outlined auto-grow row-height="30" rows="4" hide-details
                            placeholder=""
                            v-model="included_items"></v-textarea>
                    </v-col>

                    <v-col cols="12" v-if="purpose == 'buyer' || purpose == 'adopter'">
                        <div class="primary--text text-subtitle-2 font-weight-600">Preferred payment method</div>
                        <v-textarea class="mt-1" dense outlined auto-grow row-height="30" rows="4" hide-details
                            placeholder=""
                            v-model="preferred_payment_method"></v-textarea>
                    </v-col>

                    <!-- <v-col cols="12" class="py-0">
                    <div class="pa-1 primary--text text-subtitle-1 font-weight-medium">Location</div>

                    <v-alert v-model="locationPicked" class="pl-0">
                        <v-row align="center">
                            <v-col class="grow">
                                {{location ? location.description : ''}}
                            </v-col>
                            <v-col class="shrink">
                                <v-btn icon @click="clearLocation">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-alert>

                    <div v-show="!locationPicked">

                        <v-autocomplete v-model="location" :items="searchResults" :search-input.sync="search" flat hide-no-data placeholder="Please type your locality" item-text="description" item-value="placeId" clearable hint="So that we can find matches closer to you" persistent-hint prepend-inner-icon="mdi-map-marker-outline" return-object @change="getPlaceDetails" @click:clear="clearLocation"></v-autocomplete>

                        <div class="py-4">
                            <v-btn text small color="primary" @click="getCurrentLocation" :disabled="gettingLocation">
                                <v-icon left>mdi-crosshairs-gps</v-icon> {{gettingLocation ? 'Getting your location' : 'get your current location'}}
                            </v-btn>
                        </div>

                    </div>

                </v-col> -->

                    <v-col cols="12" class="pt-4">
                        <div class="primary--text text-subtitle-2 font-weight-600">photos & Videos</div>

                        <v-row justify="start" align="center" class="pt-4">

                            <v-col cols="4" :key="idx" v-for="(pic, idx) in petPics" align="center"
                                v-show="pic.avatar===0 && pic.certificate===0">
                                <v-img :src="pic.photo" cover style="border-radius:5px" aspect-ratio="1">
                                    <v-btn icon dark absolute top right class="mt-n4 mr-n4" @click="deletePic(pic.xid)" style="background-color:rgba(0,0,0,0.30)">
                                        <v-icon>
                                            mdi-close
                                        </v-icon>
                                    </v-btn>
                                </v-img>

                            </v-col>
                            <canvas id="canvas-element" style="display: none"></canvas>
                            <video id="video-element" style="display: none"></video>

                            <v-col cols="4" :key="'video'+idx" v-for="(vid, idx) in petVideos">
                                <v-img :src="vid.thumb_url" cover style="border-radius:5px" class="d-flex align-center justify-center" aspect-ratio="1" v-if="vid.thumb_url">
                                    <v-btn icon dark absolute top right class="mt-n4 mr-n4" @click="deleteVid(vid.xid)" style="background-color:rgba(0,0,0,0.30)">
                                        <v-icon>
                                            mdi-close
                                        </v-icon>
                                    </v-btn>
                                    <v-btn icon dark @click="openVideoPlayer(vid.video_url)" style="background-color:rgba(0,0,0,0.30); position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">
                                        <v-icon>
                                            mdi-play
                                        </v-icon>
                                    </v-btn>
                                </v-img>
                                <v-img src="/img/icons/player.png" cover style="border-radius:5px" aspect-ratio="1" v-else>
                                    <v-btn icon dark absolute top right class="mt-n4 mr-n4" @click="deleteVid(vid.xid)" style="background-color:rgba(0,0,0,0.30)">
                                        <v-icon>
                                            mdi-close
                                        </v-icon>
                                    </v-btn>
                                    <v-btn icon dark @click="openVideoPlayer(vid.video_url)" style="background-color:rgba(0,0,0,0.30); position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">
                                        <v-icon>
                                            mdi-play
                                        </v-icon>
                                    </v-btn>
                                </v-img>
                            </v-col>

                            <v-col cols="4" :key="-1" class="pa-4" align="center">
                                <v-card flat
                                    style="border-radius:5px; border: 2px dashed #adb5bd; width: 90px; height: 90px;"
                                    @click="launchPetPhotoPicker">
                                    <v-icon class="pa-6" color="primary" size="36">mdi-image-plus</v-icon>
                                </v-card>
                            </v-col>

                            <input type="file" ref="portfoliopic" name="portfoliopic" @change="loadImage($event)"
                                style="display:none"
                                accept="image/png, image/jpg, image/jpeg, video/mp4, video/quicktime">

                            <v-dialog v-model="portfolioDialog" width="500" :fullscreen="$vuetify.breakpoint.mobile">
                                <v-card color="black">
                                    <v-toolbar flat dark color="black">
                                        <v-btn icon @click="portfolioDialog = false" color="grey">
                                            <v-icon>mdi-close</v-icon>
                                        </v-btn>
                                        <v-spacer></v-spacer>

                                        <v-btn icon color="white" @click="savePic" :loading="psubmitting"
                                            :disabled="psubmitting">
                                            <v-icon>mdi-check</v-icon>
                                        </v-btn>

                                    </v-toolbar>
                                    <v-card-text class="pa-0">
                                        <cropper ref="cropper" :src="selectedFile" :canvas="{
                                                  height: 600,
                                                  width: 800
                                                }" :stencil-props="{
                                                    aspectRatio: 8/6,
                                                    movable: true,
                                                    scalable: true,
                                                }" image-restriction="stencil" />

                                    </v-card-text>
                                </v-card>
                            </v-dialog>

                        </v-row>

                    </v-col>

                </v-row>

            </v-col>
        </v-row>

     

        <v-dialog v-model="videoPlayerDialog" @click:outside="closeVideoPlayer" max-width="90vw" max-height="90vh">
        <v-card color="black" flat>
            <v-card-title class="d-flex justify-end">
            <v-btn icon @click="closeVideoPlayer">
                <v-icon color="grey">mdi-close</v-icon>
            </v-btn>
            </v-card-title>
            <v-card-text class="d-flex justify-center align-center pa-0">
            <v-responsive 
                class="video-responsive"
                :aspect-ratio="16 / 9" 
            >
                <video
                class="v-player"
                controls
                :src="this.video_url"
                style="width: 100%; height: auto; max-height: 80vh; object-fit: contain;"
                ></video>
            </v-responsive>
            </v-card-text>
        </v-card>
        </v-dialog>

        <v-bottom-sheet v-model="photoDeleteConfirm">
            <v-sheet class="px-7 pt-7 pb-4 mx-auto text-center d-inline-block" color="dark darken-2" dark>
                <div class="grey--text text--lighten-1 text-body-2 mb-4">
                    Are you sure you want to delete this photo?
                </div>

                <v-btn :disabled="picDeleting" class="ma-1 pr-16" color="grey" plain @click="photoDeleteConfirm=false">
                    Cancel
                </v-btn>

                <v-btn :loading="picDeleting" class="ma-1 pl-16" color="red" plain @click="deletePhoto">
                    Delete
                </v-btn>
            </v-sheet>
        </v-bottom-sheet>


        <v-bottom-sheet v-model="videoDeleteConfirm">
            <v-sheet class="px-7 pt-7 pb-4 mx-auto text-center d-inline-block" color="dark darken-2" dark>
                <div class="grey--text text--lighten-1 text-body-2 mb-4">
                    Are you sure you want to delete this video?
                </div>

                <v-btn :disabled="picDeleting" class="ma-1 pr-16" color="grey" plain @click="videoDeleteConfirm=false">
                    Cancel
                </v-btn>

                <v-btn :loading="picDeleting" class="ma-1 pl-16" color="red" plain @click="deleteVideo">
                    Delete
                </v-btn>
            </v-sheet>
        </v-bottom-sheet>

        <v-btn fab fixed bottom right color="primary" @click="savePet" :loading="submitting" :disabled="submitting">
            <v-icon>mdi-check</v-icon>
        </v-btn>

        <v-snackbar v-model="snackbar">
            {{ snackbar_text }}
            <template v-slot:action="{ attrs }">
                <v-btn color="secondary" icon v-bind="attrs" @click="snackbar = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>

        <v-overlay :value="dataLoading">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
import {
    Cropper
} from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import 'vue-advanced-cropper/dist/theme.compact.css';
//import gmapsInit from '../../plugins/gmaps';

export default {
    name: 'EditPet',
    props: ['id'],
    async mounted() {
        /* const google = await gmapsInit();
        this.service = new google.maps.places.AutocompleteService();
        this.geocoder = new google.maps.Geocoder(); */

        if (this.$store.getters.petTypes.length == 0) {
            this.getStaticData();
        }
        this.getPetDetails();
    },

    data: () => ({

        snackbar: false,
        snackbar_text: '',

        pet: {},

        yes_no_arr: [{ text: 'Yes', value: 1 },
                    { text: 'No', value: 0 }],

        yes_no_dk_arr: [{ text: 'Yes', value: 1 },
                    { text: 'No', value: 0 },
                    { text: 'Dont Know', value: '' }],

        purpose: '',
        pet_type: '',
        name: '',
        age_months: '',
        age_years: '',
        gender: '',
        breed_type: 'purebred',
        crossbred: false,
        purebred: false,
        breed1: '',
        breed2: '',
        pedigree_certified: 0,
        dna_tested: 0,
        size: '',
        weight_number: '',
        weight_unit: 'lbs',
        description: '',
        price:'',
        insta_handle: '',

        vaccinated: '',
        neutered: '',
        mating_first_time: '',
        good_with_kids: '',
        good_with_cats: '',
        good_with_other_dogs: '',
        potty_trained: '',
        price_ccy: '$ USD',
        adoption_fees: '',
        adoption_fees_ccy: '$ USD',
        stud_fees: '',
        stud_fees_ccy: '$ USD',

        distance: '50',

        /* location: {
            description: '',
            placeId: '',
            state: '',
            country: '',
            zip: '',
            locality: '',
        },
        searchResults: [],
        service: null,
        geocoder: null,
        sessionToken: null,
        search: null,
        cordinates: null,
        locationPicked: false,

        gettingLocation: false, */
        dataLoading: false,

        petPics: [],
        petVideos: [],
        petPicsBlobs: [],
        selectedFile: '',
        portfolioDialog: false,
        portfolioFile: null,
        psubmitting: false,

        submitting: false,

        petAvatar: null,
        petAvatarBlob: null,
        avatarDialog: false,
        asubmitting: false,
        avatarFile: null,
        photoDeleteConfirm: false,
        picDeleting: false,
        picToDelete: null,
        videoDeleteConfirm: false,
        videoDeleting: false,
        videoToDelete: null,

        willingness_to_travel: '',
        breeding_terms: '',
        accommodation_responsibility: '',
        preferred_payment_method: '',


        breeding_count: '',
        is_microchipped: '',
        is_house_trained: '',
        familiar_with_breed_standard: '',
        consulted_veterinarian: '',
        willing_to_sign_contract: '',
        willing_to_meet: '',
        health_issues: '',
        care_and_placement_plan: '',
        health_test_results: '',
        pet_source: '',
        living_situation: '',
        diet_and_exercise: '',
        rehoming_reason: '',
        included_items: '',
        videoPlayerDialog: false,
        video_url: '',

        yearRules: [
            v => !v ? true : /^\d*$/.test(v) || 'Age must be valid',
            v => !v || v <= 20 || 'Age must be valid',
        ],

        monthRules: [
            v => !v ? true : /^\d*$/.test(v) || 'Age must be valid',
            v => !v || v <= 12 || 'Month must be valid',

        ],

        weightRules: [
            v => !v ? true : /^\d*$/.test(v) || 'Weight must be valid'
        ],

        priceRules:  [
                v => !v ? true : /^\d*$/.test(v) || 'Price must be valid'
        ],

    }),

    components: {
        Cropper
    },

    /* metaInfo() {
        return {
            script: [{
                src: `https://maps.googleapis.com/maps/api/js?key=AIzaSyCthrQwVxKfF-rjDqqXyAyltUztn4dCwUc&libraries=places`,
                async: true,
                defer: true,
                callback: () => this.MapsInit() // will declare it in methods
            }]
        }
    }, */

    computed: {

    purpose_arr() {
      return this.$store.getters.location == 'IN'
        ? [{ text: 'Lover (Mating Partner)', value: 'lover' },
           { text: 'Friend (Playmate)', value: 'friend' }]
        : [{ text: 'Lover (Mating Partner)', value: 'lover' },
           { text: 'Friend (Playmate)', value: 'friend' },
           { text: 'Adopter', value: 'adopter' },
           { text: 'Buyer', value: 'buyer' }];
    },

        typeBreeds() {
            let petTypes = this.$store.getters.petTypes;
            let selectedPetType = this.pet_type;

            let filteredItem = petTypes.filter(item => {
                return item.xid == selectedPetType
            });

            if (filteredItem.length > 0)
                return filteredItem[0].breeds;
            else
                return [];
        },

    },

    watch: {

        breed_type(val)
        {
            if(val == 'purebred')
            {
                this.purebred = true;
                this.crossbred = false;
                this.breed2 = '';
            }
            else
            {
                this.purebred = false;
                this.crossbred = true;
            }
        },

        /* crossbred() {
            if (this.crossbred)
                this.purebred = false;
        },

        purebred() {
            if (this.purebred) {
                this.crossbred = false;
                this.breed2 = ''
            } 

        },*/

        /* search(val) {
            if (val != '' && val !== null)
                {
                    if((val.length > 3) && (val.length % 2 == 0)) {
                    this.service.getPlacePredictions({
                        input: val,
                        //sessionToken: this.sessionToken,
                        types: ['geocode']
                    }, this.displaySuggestions)
                }
            } 
        } */
    },

    methods: {
        getVideo() {
            return '/img/icons/player.png';
        },
        filterObject(item, queryText, itemText) {
            return (
                item.breed.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 /* ||
                item.description.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 */
            );
        },

        openVideoPlayer(video) {
          this.videoPlayerDialog = true;
          this.video_url = video;
        },
        closeVideoPlayer()
        {
            this.videoPlayerDialog = false;
            const videos_s = document.querySelectorAll('.v-player');
            videos_s.forEach(video => {
            video.pause();
            });
        },
        getStaticData() {
            this.dataLoading = true;
            this.$store.dispatch('getStaticData').then(() => {
                    this.dataLoading = false;
                })
                .catch(err => {
                    //console.log(err);
                    this.dataLoading = false;
                    this.snackbar_text = "Oops, there is an error loading details for the page";
                    this.snackbar = true;
                });
        },
        getPetDetails() {
            this.dataLoading = true;
            this.$store.dispatch('petFromId', this.id).then((resp) => {
                    this.pet = resp.data;
                    this.dataLoading = false;

                    this.purpose = this.pet.purpose
                    this.pet_type = this.pet.pet_type.xid
                    this.size = this.pet.pet_size.xid
                    this.name = this.pet.name
                    this.age_months = this.pet.age.months ? this.pet.age.months : 0
                    this.age_years = this.pet.age.years ? this.pet.age.years : 0
                    this.gender = this.pet.gender;
                    this.crossbred = this.pet.crossbred ? true : false;
                    this.purebred = this.pet.purebred ? true : false;
                    this.breed1 = this.pet.primary_breed;
                    this.breed2 = this.pet.secondary_breed ? this.pet.secondary_breed : null;
                    this.pedigree_certified = this.pet.pedigree_certified
                    this.dna_tested = this.pet.dna_tested
                    this.weight_number = this.pet.weight
                    this.weight_unit = this.pet.weight_unit
                    this.description = this.pet.description
                    this.price = this.pet.price
                    this.insta_handle = this.pet.insta_handle
                    this.petAvatar = this.pet.avatar ? this.pet.avatar : null;
                    this.petPics = this.pet.photos;
                    this.petVideos = this.pet.videos;
                    /* this.location = {
                        description: this.pet.location_description,
                        placeId: this.pet.place_id,
                        state: this.pet.state,
                        country: this.pet.country,
                        zip: this.pet.zip,
                        locality: this.pet.locality,
                    };
                    this.locationPicked = true;
                    this.cordinates = {
                        lat: this.pet.latitude,
                        lng: this.pet.longitude
                    }; */

                    this.vaccinated = this.pet.vaccinated == null ? '' : this.pet.vaccinated;
                    this.neutered = this.pet.neutered == null ? '' : this.pet.neutered;
                    this.mating_first_time = this.pet.mating_first_time == null ? '' : this.pet.mating_first_time;
                    this.good_with_kids = this.pet.good_with_kids == null ? '' : this.pet.good_with_kids;
                    this.good_with_cats = this.pet.good_with_cats == null ? '' : this.pet.good_with_cats;
                    this.good_with_other_dogs = this.pet.good_with_other_dogs == null ? '' : this.pet.good_with_other_dogs;
                    this.potty_trained = this.pet.potty_trained == null ? '' : this.pet.potty_trained;
                    this.price_ccy = this.pet.price_ccy == null ? '$ USD' : this.pet.price_ccy;
                    this.adoption_fees = this.pet.adoption_fees;
                    this.adoption_fees_ccy = this.pet.adoption_fees_ccy == null ? '$ USD' : this.pet.adoption_fees_ccy;
                    this.stud_fees = this.pet.stud_fees;
                    this.stud_fees_ccy = this.pet.stud_fees_ccy == null ? '$ USD' : this.pet.stud_fees_ccy;

                    this.willingness_to_travel = this.pet.willingness_to_travel == null ? '' :  this.pet.willingness_to_travel;
                    this.breeding_terms = this.pet.breeding_terms == null ? '' : this.pet.breeding_terms;
                    this.accommodation_responsibility = this.pet.accommodation_responsibility == null ? '' : this.pet.accommodation_responsibility;
                    this.preferred_payment_method = this.pet.preferred_payment_method == null ? '' :  this.pet.preferred_payment_method;


                    this.breeding_count = this.pet.breeding_count == null ? '' :  this.pet.breeding_count;
                    this.is_microchipped = this.pet.is_microchipped == null ? '' :  this.pet.is_microchipped;
                    this.is_house_trained = this.pet.is_house_trained == null ? '' :  this.pet.is_house_trained;
                    this.familiar_with_breed_standard = this.pet.familiar_with_breed_standard == null ? '' :  this.pet.familiar_with_breed_standard;
                    this.consulted_veterinarian = this.pet.consulted_veterinarian == null ? '' :  this.pet.consulted_veterinarian;
                    this.willing_to_sign_contract = this.pet.willing_to_sign_contract == null ? '' :  this.pet.willing_to_sign_contract;
                    this.willing_to_meet = this.pet.willing_to_meet == null ? '' :  this.pet.willing_to_meet;
                    this.health_issues = this.pet.health_issues == null ? '' :  this.pet.health_issues;
                    this.care_and_placement_plan = this.pet.care_and_placement_plan == null ? '' :  this.pet.care_and_placement_plan;
                    this.health_test_results = this.pet.health_test_results == null ? '' :  this.pet.health_test_results;
                    this.pet_source = this.pet.pet_source == null ? '' :  this.pet.pet_source;
                    this.living_situation = this.pet.living_situation == null ? '' :  this.pet.living_situation;
                    this.diet_and_exercise = this.pet.diet_and_exercise == null ? '' :  this.pet.diet_and_exercise;
                    this.rehoming_reason = this.pet.rehoming_reason == null ? '' :  this.pet.rehoming_reason;
                    this.included_items = this.pet.included_items == null ? '' :  this.pet.included_items;



                })
                .catch(err => {
                    this.overlay = false;
                    this.snackbar_text = "Oops, Unable to get the pet";
                    this.snackbar = true;
                    this.$router.replace('/home');
                });

        },

        launchPetPhotoPicker() {
            this.$refs.portfoliopic.click();
        },


        loadImage(event) {
            var input = event.target;
            if (input.files && input.files[0]) {
                let uploadedFile = input.files[0];
                if(uploadedFile['type'].split('/')[0] === 'video') {
                    let vidsize = (uploadedFile['size'] / (1024*1024)).toFixed(2);
                    if (vidsize > 50) {
                      this.snackbar_text = "Video size must be less than 50MB.";
                      this.snackbar = true;
                    } else {
                      this.saveVideo(uploadedFile);
                    }
                } else {
                    // Check if image size is less than 5 MB
                    let imgSize = (uploadedFile['size'] / (1024*1024)).toFixed(2);
                    if (imgSize > 5) {
                        this.snackbar_text = "Image size must be less than 5MB.";
                        this.snackbar = true;
                    } else {
                        var reader = new FileReader();

                        reader.onload = (e) => {
                            this.selectedFile = e.target.result;
                            this.portfolioDialog = true;
                        };
                        reader.readAsDataURL(input.files[0]);
                    }
                }
            }
        },

        saveVideo(video) {

        this.submitting = true;

        const file_extension = video.name.split('.').pop(); // Get file extension
        const filename = video.name.split('.').slice(0, -1).join('.'); // Get the base filename
        const folder = 'pet_videos';
        const file_type = video.type;

        this.$store.dispatch('generateSignedUrl', {
            file_extension, filename, folder, file_type
                    })
                        .then((resp) => {
                            //console.log(resp);
                            const signedUrl = resp.data.url;
                            const filePath = resp.data.path;

                            //console.log(video.type);

                            const xhr = new XMLHttpRequest()
                            xhr.open('PUT', signedUrl, true)
                            xhr.onload = () => {
                            const status = xhr.status
                            if (status === 200) {
                                //console.log("File uploaded successfully:", filePath);
                                const fileUrl = `https://storage.googleapis.com/${process.env.VUE_APP_GOOGLE_CLOUD_STORAGE_BUCKET}/${filePath}`;

                                let formData = new FormData();

                                formData.append('id', this.id);
                                formData.append('video_url', fileUrl);
                                formData.append('file_path', filePath);

                                this.$store.dispatch('addVideo', formData)
                                    .then((resp) => {
                                        this.$store.dispatch('getPetDetails', this.id).then((resp) => {
                                        this.petVideos = resp.data.videos;
                                        this.submitting = false;
                                        });
                                    })
                                    .catch(err => {
                                        this.snackbar_text = "Error while updating";
                                        this.snackbar = true;
                                        this.submitting = false;
                                    }); 

                            } else {
                                this.submitting = false;
                                this.snackbar_text = "Error uploading";
                                this.snackbar = true;
                            }
                            }
                            xhr.onerror = () => {
                                this.submitting = false;
                                this.snackbar_text = "Error uploading";
                                this.snackbar = true;
                            }

                            xhr.upload.onprogress = function (event) {
                                if (event.lengthComputable) {
                                    this.video_upload_completion = (event.loaded / event.total) * 100;
                                    //console.log(this.video_upload_completion);
                                    //console.log(`Upload progress: ${percentComplete.toFixed(2)}%`);
                                }
                            };

                            xhr.setRequestHeader('Content-Type', video.type)
                            xhr.send(video)
                        })
                        .catch(err => {
                            this.submitting = false;
                            this.snackbar_text = "Error uploading";
                            this.snackbar = true;
                        });


        },

        /* saveVideo(video) {
          this.submitting = true;

          let formData = new FormData();

          formData.append('id', this.id);
          formData.append('video', video);

          this.$store.dispatch('addVideo', formData)
              .then((resp) => {
                this.$store.dispatch('getPetDetails', this.id).then((resp) => {
                  this.petVideos = resp.data.videos;
                  this.submitting = false;
                });
              })
              .catch(err => {
                this.snackbar_text = "Error while updating";
                this.snackbar = true;
                this.submitting = false;
              });

        }, */
        
        savePic() {
            this.psubmitting = true;
            const {
                canvas
            } = this.$refs.cropper.getResult();
            if (canvas) {

                canvas.toBlob((blob) => {

                    let formData = new FormData();

                    formData.append('id', this.id);
                    formData.append('photo', blob);

                    this.$store.dispatch('addPhoto', formData)
                        .then((resp) => {
                            this.$store.dispatch('getPetDetails', this.id).then((resp) => {
                                this.petPics = resp.data.photos;
                                this.portfolioDialog = false;
                                this.psubmitting = false;
                            });
                        })
                        .catch(err => {
                            if(err.response.data.message)
                                {
                                    this.snackbar_text = "Error Uploading: "+err.response.data.message;
                                }
                                else
                                {
                                    this.snackbar_text = "Error uploading image";
                                }
                            this.snackbar = true;
                            this.psubmitting = false;
                            //this.portfolioDialog = false;
                        });

                }, "image/jpeg");

            }
        },

        deletePic(xid) {
            this.picToDelete = xid
            this.photoDeleteConfirm = true;
        },

        deleteVid(xid) {
            this.videoToDelete = xid
            this.videoDeleteConfirm = true;
        },

        launchAvatarPicker() {
            this.$refs.avatarpic.click();
        },

        loadAvatar(event) {
            var input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.onload = (e) => {
                    this.avatarFile = e.target.result;
                    this.avatarDialog = true;

                };
                reader.readAsDataURL(input.files[0]);
            }
        },

        saveAvatar() {
            this.asubmitting = true;
            const {
                canvas
            } = this.$refs.acropper.getResult();
            if (canvas) {

                canvas.toBlob((blob) => {

                    let formData = new FormData();

                    formData.append('id', this.id);
                    formData.append('avatar', blob);

                    this.$store.dispatch('addAvatar', formData)
                        .then((resp) => {
                            this.$store.dispatch('getPetDetails', this.id).then((resp) => {
                                this.petAvatar = resp.data.avatar;
                                this.avatarDialog = false;
                                this.asubmitting = false;
                            });
                        })
                        .catch(err => {
                            if(err.response.data.message)
                                {
                                    this.snackbar_text = "Error Uploading: "+err.response.data.message;
                                }
                                else
                                {
                                    this.snackbar_text = "Error uploading image";
                                }

                            this.snackbar = true;
                            this.asubmitting = false;
                        });

                }, "image/jpeg");

            }
        },

        

        savePet() {

            if (this.pet == '') {
                this.snackbar_text = "Please select the type of pet";
                this.snackbar = true;
            } else if (this.purpose == '') {
                this.snackbar_text = "Please select your purpose";
                this.snackbar = true;
            } else if (this.name == '') {
                this.snackbar_text = "Please enter your pet's name";
                this.snackbar = true;
            } else if (this.name.includes("@")) {
                this.snackbar_text = "Please enter a valid name";
                this.snackbar = true;
            } else if (this.gender == '') {
                this.snackbar_text = "Please select your pet's gender";
                this.snackbar = true;
            } else if (this.breed1 == '' || this.breed1 == null) {
                this.snackbar_text = "Please select your pet's breed";
                this.snackbar = true;
            } else if (this.age_months == '' && this.age_years == '') {
                this.snackbar_text = "Please enter your pet's age";
                this.snackbar = true;
            } else if (this.weight_number == '') {
                this.snackbar_text = "Please enter your pet's weight";
                this.snackbar = true;
            } else if (this.size == '') {
                this.snackbar_text = "Please select your pet's size";
                this.snackbar = true;
            } else if (this.description == '') {
                this.snackbar_text = "Please write down bit more details of your pet";
                this.snackbar = true;
            /* } else if (!this.location || this.location.description == '') {
                this.snackbar_text = "Please pick your location";
                this.snackbar = true; */
            } else if (this.purpose == 'buyer' && this.price == '') {
                this.snackbar_text = "Please enter a price";
                this.snackbar = true;
            }else {

                this.submitting = true;

                let formData = new FormData();

                formData.append('id', this.id);
                formData.append('pet_type', this.pet_type);
                formData.append('size', this.size);
                formData.append('purpose', this.purpose);
                formData.append('crossbred', this.crossbred);
                formData.append('purebred', this.purebred);
                formData.append('breed1', JSON.stringify(this.breed1));
                formData.append('breed2', JSON.stringify(this.breed2));
                formData.append('pedigree_certified', this.pedigree_certified);
                formData.append('dna_tested', this.dna_tested);
                formData.append('gender', this.gender);
                formData.append('name', this.name);
                formData.append('age_months', this.age_months);
                formData.append('age_years', this.age_years);
                formData.append('weight_number', this.weight_number);
                formData.append('weight_unit', this.weight_unit);
                formData.append('description', this.description);
                formData.append('price', this.price);
                formData.append('insta_handle', this.insta_handle);
                //formData.append('location', JSON.stringify(this.location));
                //formData.append('cordinates', JSON.stringify(this.cordinates));

                formData.append('vaccinated', this.vaccinated);
                formData.append('neutered', this.neutered);
                formData.append('mating_first_time', this.mating_first_time);
                formData.append('good_with_kids', this.good_with_kids);
                formData.append('good_with_cats', this.good_with_cats);
                formData.append('good_with_other_dogs', this.good_with_other_dogs);
                formData.append('potty_trained', this.potty_trained);
                formData.append('price_ccy', this.price_ccy);
                formData.append('adoption_fees', this.adoption_fees);
                formData.append('adoption_fees_ccy', this.adoption_fees_ccy);
                formData.append('stud_fees', this.stud_fees);
                formData.append('stud_fees_ccy', this.stud_fees_ccy);

                formData.append('willingness_to_travel', this.willingness_to_travel);
                formData.append('breeding_terms', this.breeding_terms);
                formData.append('accommodation_responsibility', this.accommodation_responsibility);
                formData.append('preferred_payment_method', this.preferred_payment_method);

                formData.append('is_microchipped', this.is_microchipped);
                formData.append('is_house_trained', this.is_house_trained);
                formData.append('breeding_count', this.breeding_count);
                formData.append('familiar_with_breed_standard', this.familiar_with_breed_standard);
                formData.append('consulted_veterinarian', this.consulted_veterinarian);
                formData.append('care_and_placement_plan', this.care_and_placement_plan);
                formData.append('health_test_results', this.health_test_results);
                formData.append('willing_to_sign_contract', this.willing_to_sign_contract);
                formData.append('pet_source', this.pet_source);
                formData.append('living_situation', this.living_situation);
                formData.append('diet_and_exercise', this.diet_and_exercise);
                formData.append('rehoming_reason', this.rehoming_reason);
                formData.append('included_items', this.included_items);
                formData.append('willing_to_meet', this.willing_to_meet);
                formData.append('health_issues', this.health_issues);

                let xid = this.id

                this.$store.dispatch('editPet', {
                        xid,
                        formData
                    })
                    .then((resp) => {

                        /* gtag('event', 'Registration Completed', {
                            event_category: 'Registration',
                            event_label: 'New User',
                        });

                        gtag('event', 'conversion', {
                            'send_to': 'AW-988269150/eCSFCMztk9MBEN6Un9cD',
                            'value': 1.0,
                            'currency': 'BRL'
                        }); */

                        /* fbq('track', 'CompleteRegistration', {
                            currency: "USD",
                            value: 0
                        }); */

                        /* profitwell('start', { 'user_email': this.userEmail }); */

                        this.submitting = false;
                        this.$router.push('/home');

                    })
                    .catch(err => {
                        //console.log(err);
                        //console.log(err.response.data.error['userEmail'][0])

                        this.snackbar_text = "Oops, We encountered an error while saving. Please try again.";
                        this.snackbar = true;
                        this.submitting = false;
                    });
            }

        },

        deletePhoto() {
            this.picDeleting = true;

            this.$store.dispatch('deletePhoto', this.picToDelete)
                .then((resp) => {
                    this.$store.dispatch('getPetDetails', this.id).then((resp) => {
                        this.petPics = resp.data.photos;
                        this.petAvatar = resp.data.avatar;
                        this.picDeleting = false;
                        this.photoDeleteConfirm = false;
                    });

                })
                .catch(err => {
                    //console.log(err);

                });

        },

        deleteVideo() {
          this.videoDeleting = true;

          this.$store.dispatch('deleteVideo', this.videoToDelete)
              .then((resp) => {
                this.$store.dispatch('getPetDetails', this.id).then((resp) => {
                  this.petVideos = resp.data.videos;
                  this.videoDeleting = false;
                  this.videoDeleteConfirm = false;
                });

              })
              .catch(err => {
                //console.log(err);

              });
        },
    }
}
</script>

<style scoped>
.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)>>>fieldset {
    border-color: #adb5bd;
}
</style>

<style>
.video-responsive {
  max-height: 80vh; /* Ensures the video fits within the screen */
}

@media (orientation: portrait) {
  /* For very tall portrait videos on mobile */
  .video-responsive video {
    max-width: 100vw;
    max-height: 80vh;
  }
}

@media (min-width: 600px) {
  /* For larger screens, limit the height */
  .video-responsive video {
    max-width: 90vw;
    max-height: 80vh;
  }
}
</style>
