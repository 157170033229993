import Vue from 'vue'
import VueMoment from 'vue-moment'
import VueCookie from 'vue-cookie'
import Axios from 'axios'

import Main from './layouts/Main.vue'
import App from './layouts/App.vue'
//import './registerServiceWorker'

import wb from "./registerServiceWorker";

Vue.prototype.$workbox = wb;

import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

Bugsnag.start({
  apiKey: process.env.VUE_APP_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginVue()],
  releaseStage: process.env.VUE_APP_BUGSNAG_RELEASE_STAGE
})

const bugsnagVue = Bugsnag.getPlugin('vue')
bugsnagVue.installVueErrorHandler(Vue)


import './styles/custom.scss'

import VueSocialSharing from 'vue-social-sharing'

Vue.use(VueSocialSharing);

import VueClipboard from 'vue-clipboard2'

VueClipboard.config.autoSetContainer = true // add this line
Vue.use(VueClipboard)

import VueMeta from 'vue-meta'
 
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

Vue.use(VueMoment)
Vue.use(VueCookie)

Vue.config.productionTip = false

if (Vue.cookie.get('pm_token')) {
  Axios.defaults.headers.common['Authorization'] = 'Bearer '+Vue.cookie.get('pm_token');
}
else{
    Axios.defaults.headers.common['Authorization'] = '';
}

Axios.defaults.baseURL = process.env.VUE_APP_API_URL+'/api'

/* const img = new Image();
img.onload = () => {
  Vue.prototype.$loaderImageUrl = img.src; 
};
img.src = require('@/assets/img/loading_25.gif'); */

Vue.prototype.$loaderImageUrl = require('@/assets/img/loading_25.gif');

new Vue({
  router,
  store,
  vuetify,
  render: h => h(store.getters.isLoggedIn ? App : Main),
  created() {
    const locationData = Vue.cookie.get('pm_location');
    if (!locationData) {
      this.$store.dispatch('fetchLocation');
    } 
  }
}).$mount('#app')
