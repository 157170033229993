<template>
    <div>
      <div ref="map" style="width: 100%; height: 200px;"></div>
    </div>
  </template>
  
  <script>
  import { Loader } from '@googlemaps/js-api-loader';
  
  export default {
    name: 'GoogleMap',
    props: {
      placeId: {
        type: String,
        required: true,
      },
      apiKey: {
        type: String,
        required: true,
      },
    },
    mounted() {
      this.initMap();
    },
    methods: {
      async initMap() {
        const loader = new Loader({
          apiKey: this.apiKey,
          version: 'weekly',
        });
  
        try {
          const google = await loader.load();
          const map = new google.maps.Map(this.$refs.map, {
            center: { lat: 0, lng: 0 }, // Temporary center, will be updated
            zoom: 14,
          });
  
          const service = new google.maps.places.PlacesService(map);
  
          // Get place details using the place_id
          service.getDetails(
            {
              placeId: this.placeId,
              fields: ['geometry'],
            },
            (place, status) => {
              if (status === google.maps.places.PlacesServiceStatus.OK && place.geometry) {
                const location = place.geometry.location;
  
                // Update map center
                map.setCenter(location);
  
                // Add marker to the map
                new google.maps.Marker({
                  position: location,
                  map: map,
                });
              } else {
                console.error('Failed to load place details:', status);
              }
            }
          );
        } catch (error) {
          console.error('Google Maps API failed to load:', error);
        }
      },
    },
  };
  </script>
  
  <style scoped>
  /* Optional styling for the map container */
  div[ref="map"] {
    border: 1px solid #ccc;
    border-radius: 8px;
  }
  </style>