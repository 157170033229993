<template>
<div class="search pa-4">
    <v-app-bar app flat dark color="primary" extended extension-height="72">

        <v-btn icon :to="'/home'" v-if="this.$store.getters.previousRoute == null">
            <v-icon>mdi-home</v-icon>
        </v-btn>
        <v-btn icon @click="$router.go(-1)" v-else>
            <v-icon>mdi-arrow-left</v-icon>
        </v-btn>

        <v-toolbar-title class="text-subtitle-1 font-weight-medium">Matches for {{pet.name ? pet.name : ''}}</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon @click="filterDialog = true">
            <v-icon>$vuetify.icons.filter-icon</v-icon>
        </v-btn>

        <template v-slot:extension>
            <div @click="filterDialog = true">
                <v-chip class="ma-1" small color="#f0e7ff" text-color="primary">
                    Within {{distance}} miles
                </v-chip>
                <v-chip class="ma-1" small color="#f0e7ff" text-color="primary" v-if="purebred">
                    Purebred
                </v-chip>
                <v-chip class="ma-1" small color="#f0e7ff" text-color="primary" v-if="pedigree_certified">
                    Pedigree Certified
                </v-chip>
                <v-chip class="ma-1" small color="#f0e7ff" text-color="primary" v-if="dna_tested">
                    DNA Certified
                </v-chip>
                <v-chip class="ma-1" small color="#f0e7ff" text-color="primary" v-if="breeds.length > 1">
                    Of {{breeds.length}} selected breeds
                </v-chip>
                <v-chip class="ma-1" small color="#f0e7ff" text-color="primary" v-if="$store.getters.sizeDetails(size)">
                    {{size == '' ? 'Any' : $store.getters.sizeDetails(size).size}} sized
                </v-chip>
                <v-chip class="ma-1" small color="#f0e7ff" text-color="primary" v-if="sortBy">
                    {{ sortBy == 'distance' ? 'Sort by Nearest First' : ''}}
                    {{ sortBy == 'last_activity' ? 'Sort by Last Activity' : ''}}
                </v-chip>
            </div>

        </template>

    </v-app-bar>

    <!-- <v-row>
        <v-col cols="12" sm="6" md="4" lg="3" xl="2" v-for="(item,index) in this.$store.getters.searchResults" :key="index" class="px-4 pb-2">
            <pet-card :pet="item"></pet-card>
        </v-col>
    </v-row> -->

    <v-row>
  
        <template v-for="(item, index) in this.$store.getters.searchResults">
        <!-- 1) Pet card column, with a unique key -->
            <v-col
            :key="'pet-' + index"
            cols="12"
            sm="6"
            md="4"
            lg="3"
            xl="2"
            class="px-4 pb-2"
            >
                <pet-card :pet="item"></pet-card>
            </v-col>

            <!-- Business or Affiliate Card -->
            <v-col
                v-if="specialCards[index] && specialCards[index].type !== 'none'"
                :key="'special-' + index"
                :cols="getColSize(specialCards[index].type).cols"
                :sm="getColSize(specialCards[index].type).sm"
                :md="getColSize(specialCards[index].type).md"
                :lg="getColSize(specialCards[index].type).lg"
                :xl="getColSize(specialCards[index].type).xl"
                class="px-4 pb-2"
            >
                <business-card
                    v-if="specialCards[index].type === 'business'"
                    :business="specialCards[index].data"
                />
                <affiliate-card
                    v-else-if="specialCards[index].type === 'affiliate'"
                    :business="specialCards[index].data"
                />
            </v-col>
        </template>

    </v-row>
    <infinite-loading :identifier="infiniteId" @infinite="infiniteHandler">

        <div slot="no-more">
            <v-row class="pt-6" justify="center">
                <v-col cols="12" md="6" class="py-12 px-6 text-center">

                    <div class="text-center">
                            <p class="pt-4 text-subtitle-2">Couldn't find a proper match? 🙁</p>
                            <!-- <v-icon size="80" color="#eee" class="pb-4">mdi-emoticon-sad-outline</v-icon> -->
                            <p class="pb-2 text-caption">You may try changing the <a href="javascript:void(0);" @click="filterDialog = true">filters</a>.</p>

                            <v-alert icon="mdi-email-receive-outline" prominent dark color="primary" >
                                <span class="text-caption">We will send more matches based on your filters to your inbox as we find them.</span>
                            </v-alert>
                        </div>

                        <div class="text-center">
                        
                        <v-card class="mild-shadow mt-12">
                        <v-card-text class="pa-6">
                        
                        <div class="text-h6 font-weight-bold pb-4 black--text">
                            Install Petmeetly
                        </div>
                        <div class="text-caption pb-4">
                            Save Petmeetly on your device for quick and easy access when you're on the go.
                        </div>

                        <v-row>
                            <v-col cols="12" sm="6">
                                <a href="https://apps.apple.com/app/apple-store/id6478047015?pt=118572154&ct=Web%20App%20Referral&mt=8" target="_blank">
                                <v-img src='@/assets/logo/appstore-256x256.png' max-width="180"  class="mx-auto"></v-img>
                            </a>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <a href="https://play.google.com/store/apps/details?id=com.petmeetly" target="_blank">
                                <v-img src='@/assets/logo/googleplay-badge-256.png' max-width="200" class="mx-auto"></v-img>
                                </a>
                            </v-col>
                        </v-row>


                        </v-card-text>

                        </v-card>

                    </div>

                </v-col>
            </v-row>

        </div>
        <div slot="no-results">
            <v-row class="pt-6" align="center" justify="center">
                <v-col cols="12" md="6" class="pa-12 text-center">
                    <v-icon size="120" color="#eee">mdi-heart-broken</v-icon>
                    <div class="pt-4">
                        <p>We're sorry. We couldn't find a proper match {{pet.name ? 'for '+pet.name : ''}} now.</p>
                        <p>Watch your inbox. We will let you know when we find a few suitable matches.</p>
                        <p>You may also try changing the <a href="javascript:void(0);" @click="filterDialog = true">filters</a>.</p>
                    </div>
                </v-col>
            </v-row>
        </div>
        <div slot="spinner">
            <v-row>
                <v-col cols="12" justify="center" align="center" class="my-12">
                    <!-- <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular> -->
                    <img :src="$loaderImageUrl" height="100" alt="Loading..." />
                </v-col>

            </v-row>
        </div>

    </infinite-loading>
    <!-- </div> -->
    <v-snackbar v-model="snackbar" bottom>
        {{ snackbar_text }}
        <template v-slot:action="{ attrs }">
            <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>

    <v-dialog v-model="filterDialog" :fullscreen="$vuetify.breakpoint.xsOnly" scrollable origin="top right" max-width="450" transition="dialog-transition">
        <v-card>
            <div>
                <v-toolbar flat>
                    <v-toolbar-title class="toolbar-title">
                        <span class="subtitle-custom"></span>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn icon @click.native="filterDialog=false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
            </div>

            <v-card-text class="pt-10 search-filter">

                <v-row>

                    <v-col cols="12" class="px-4 py-0">
                        <div class="primary--text pb-2 text-subtitle-2">Pets within a distance of <span class="font-weight-bold">{{distance}}</span> Miles</div>
                        <v-slider thumb-label v-model="distance" step="10" min="10" max="3000" hide-details color="primary" track-color="#ddd" track-fill-color="primary"></v-slider>
                    </v-col>

                    <v-col cols="12" class="px-4">
                        <div class="primary--text pb-2 text-subtitle-2 font-weight-600">Breeds</div>
                        <div>

                            <v-autocomplete v-model="breeds" solo flat multiple chips deletable-chips hide-no-data hide-details :filter="filterObject" :items="typeBreeds" item-text="breed" item-value="xid" return-object label="Select breeds">

                                <template v-slot:item="data">
                                    <v-list-item-content>
                                        <v-list-item-title v-html="data.item.breed"></v-list-item-title>
                                        <v-list-item-subtitle v-html="data.item.description"></v-list-item-subtitle>
                                    </v-list-item-content>
                                </template>

                            </v-autocomplete>
                        </div>

                    </v-col>

                    <v-col cols="12" class="px-4 py-0">
                        <div class="d-flex justify-space-between align-center">
                            <div class="primary--text text-subtitle-2 font-weight-600 mt-4">Purebred pets only</div>
                            <v-switch v-model="purebred" hide-details></v-switch>
                        </div>

                    </v-col>
                    <v-col cols="12" class="px-4 py-0">
                        <div class="d-flex justify-space-between align-center">
                            <div class="primary--text text-subtitle-2 font-weight-600 mt-4">Pedigree Certified Only</div>
                            <v-switch v-model="pedigree_certified" hide-details></v-switch>
                        </div>

                    </v-col>
                    <v-col cols="12" class="px-4 py-0">
                        <div class="d-flex justify-space-between align-center">
                            <div class="primary--text text-subtitle-2 font-weight-600 mt-4">DNA Certified Only</div>
                            <v-switch v-model="dna_tested" hide-details></v-switch>
                        </div>

                    </v-col>

                    <v-col cols="12" class="px-4 pt-8">
                    <div class="primary--text text-subtitle-2 font-weight-600">Size</div>

                    <v-select dense solo outlined flat hide-details v-model="size" :items="sizeOptions"
                            item-text="size" item-value="xid" placeholder="Select" class="mt-1 custom-select-padding"></v-select>


                </v-col>

                    <v-col cols="12" class="px-4 py-2">
                        <div class="primary--text text-subtitle-2 font-weight-600">Sort by</div>
                        <v-radio-group v-model="sortBy" class="mt-2">
                            <v-radio label="Default" value=""></v-radio>
                            <v-radio label="Last Activity" value="last_activity"></v-radio>
                            <v-radio label="Distance" value="distance"></v-radio>
                        </v-radio-group>
                    </v-col>

                </v-row>

            </v-card-text>

            <v-card-actions>

                <v-spacer></v-spacer>

                <v-btn block large color="primary" @click="resetAndFilter">SEARCH</v-btn>

                <v-spacer></v-spacer>

            </v-card-actions>

        </v-card>
    </v-dialog>

</div>
</template>

<script>
import PetCard from '../components/PetCard.vue'
import BusinessCard from '../components/BusinessCard.vue';
import AffiliateCard from '../components/AffiliateCard.vue';
import InfiniteLoading from 'vue-infinite-loading'
export default {
    name: 'SearchMatches',
    props: ['id'],

    mounted() {
        //console.log('mounting search')

        if (this.$store.getters.petTypes.length == 0) {
            this.getStaticData();
        }

        this.$store.dispatch('setMyPetID', this.id).then(() => {
            //set filter criterias
            //this.searching = true;

            let pet = this.$store.getters.myPetDetails(this.$store.getters.myPetID);

            if (pet) {
                this.pet = pet;

                for (let breed of pet.search_filters.breeds) {
                    this.breeds.push({
                        xid: breed
                    });
                }

                this.purebred = pet.search_filters.purebred
                this.pedigree_certified = pet.search_filters.pedigree_certified
                this.dna_tested = pet.search_filters.dna_tested
                this.distance = pet.search_filters.distance
                this.size = pet.search_filters.size ? pet.search_filters.size : ''
                this.sortBy = pet.search_filters.sort_by;

                this.$store.dispatch('clearSearchResults');
            } else {
                //Incase we cannot get the pet
                this.$router.push('/home');
            }

            //Show premium pop
            if (!this.$store.getters.isPremium && this.$store.getters.userDetails.name != "") {

                let today = this.$moment()
                let lastPrompt = this.$moment((this.$cookie.get("pm_premium_prompt_last_seen") ? this.$cookie.get("pm_premium_prompt_last_seen") : "1/1/2022"), 'M/D/YYYY')
                let days = today.diff(lastPrompt, 'days')

                if (days > 0) {
                   setTimeout(() => {
                        this.$store.dispatch('showPremiumPop', true);
                        }, 5000);
                }

            }
        });
    },

    components: {
        PetCard,
        BusinessCard,
        AffiliateCard,
        InfiniteLoading,
    },

    data() {
        return {
            snackbar: false,
            snackbar_text: '',
            pet: {
                pet_type: {
                    xid: ''
                }
            },
            favLoading: false,
            page: 1,
            infiniteId: +new Date(),
            filterDialog: false,
            breeds: [],
            purebred: false,
            pedigree_certified: false,
            dna_tested: false,
            distance: 300,
            size: '',
            dataLoading: false,
            sortBy: '',
            fetchedData: false,
            businessStatus: [],
            specialCards: {},
            
        }
    },

    computed: {

        typeBreeds() {
            let petTypes = this.$store.getters.petTypes;
            let selectedPetType = this.pet.pet_type.xid;

            let filteredItem = petTypes.filter(item => {
                return item.xid == selectedPetType
            });

            if (filteredItem.length > 0)
                return filteredItem[0].breeds;
            else
                return [];
        },

        sizeOptions() {

            const dynamicSizes = this.$store.getters.petSizes.map(item => ({
                size: item.size,
                xid: item.xid,
            }));

            const anySizeOption = { size: 'Any Size', xid: '' };

            return [anySizeOption, ...dynamicSizes];
            }

    },

    watch: {
    '$store.getters.searchResults': {
      handler(newResults) {
        // Check for special card slots as new search results load
        newResults.forEach((_, index) => {
          if (this.shouldShowSpecialCard(index) && !this.specialCards[index]) {
            this.fetchSpecialCard(index);
          }
        });
      },
      immediate: true,
    },
  },

    methods: {

        async fetchSpecialCard(index) {
        try {
            const interval = this.getDynamicInterval();
            
            const isBusinessSlot = (index + 1) % (2 * interval) === interval; // e.g., 7th, 21st
            const isAffiliateSlot = (index + 1) % (2 * interval) === 0; // e.g., 14th, 28th


            if (isBusinessSlot) {
                const sponsoredData = await this.fetchSponsored();
                if (sponsoredData) {
                    this.$set(this.specialCards, index, { type: 'business', data: sponsoredData });
                    return;
                }

                const affiliateData = await this.fetchAffiliate();
                if (affiliateData) {
                    this.$set(this.specialCards, index, { type: 'affiliate', data: affiliateData });
                    return;
                }
            } else if (isAffiliateSlot) {
                const affiliateData = await this.fetchAffiliate();
                if (affiliateData) {
                    this.$set(this.specialCards, index, { type: 'affiliate', data: affiliateData });
                    return;
                }

                const sponsoredData = await this.fetchSponsored();
                if (sponsoredData) {
                    this.$set(this.specialCards, index, { type: 'business', data: sponsoredData });
                    return;
                }
            }

            // If no data available, hide the slot
            this.$set(this.specialCards, index, { type: 'none' });
            } catch (error) {
                console.error('Error fetching special card:', error);
                this.$set(this.specialCards, index, { type: 'none' });
            }
        },

        async fetchSponsored() {
        try {
            const response = await this.$store.dispatch('getSponseredBusiness');
            return response?.data || null; // Return data if available
        } catch (error) {
            if (error.response?.status === 404) {
                console.warn('No sponsored business available:', error.response?.data?.message || error.message);
            } else {
                console.error('Error fetching sponsored business:', error);
            }
            return null; // Return null if no data is available
        }
        },

        async fetchAffiliate() {
            try {
                const isMobile = this.$vuetify.breakpoint.xs;
                const response = await this.$store.dispatch('getAffiliatedBusiness', {isMobile});
                return response?.data || null; // Return data if available
            } catch (error) {
                if (error.response?.status === 404) {
                    console.warn('No affiliated business available:', error.response?.data?.message || error.message);
                } else {
                    console.error('Error fetching affiliated business:', error);
                }
                return null; // Return null if no data is available
            }
        },

        shouldShowSpecialCard(index) {
        const interval = this.getDynamicInterval();
        return (index + 1) % interval === 0;
        },

        getDynamicInterval() {
        const breakpoints = this.$vuetify.breakpoint;
        return breakpoints.sm ? 8 : breakpoints.md || breakpoints.xs ? 7 : 10;
        },

        getColSize(type) {
        const breakpoints = this.$vuetify.breakpoint;

        if (type === 'business') {
            return {
                cols: 12,
                sm: 12,
                md: 8,
                lg: 6,
                xl: 4,
            };
        }

        // Default to affiliate card sizes (same as pet card)
        return {
            cols: 12,
            sm: 6,
            md: 4,
            lg: 3,
            xl: 2,
        };
    },

        filterObject(item, queryText, itemText) {
            return (
                item.breed.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 /* ||
                item.description.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 */
            );
        },

        getStaticData() {
            this.dataLoading = true;
            this.$store.dispatch('getStaticData').then(() => {
                    this.dataLoading = false;
                })
                .catch(err => {
                    //console.log(err);
                    this.dataLoading = false;
                    this.snackbar_text = "Oops, there is an error loading details for the page";
                    this.snackbar = true;
                });
        },

        resetAndFilter() {

            this.filterDialog = false;
            this.page = 1;
            this.$store.dispatch('clearSearchResults');
            this.infiniteId += 1;

            let id = this.id
            let purebred = this.purebred
            let pedigree_certified = this.pedigree_certified
            let dna_tested = this.dna_tested
            let distance = this.distance
            let breeds = this.breeds
            let size = this.size
            let sort_by = this.sortBy;

            this.$store.dispatch('setPetFilters', {
                id,
                purebred,
                pedigree_certified,
                dna_tested,
                distance,
                breeds,
                size,
                sort_by
            }).then(() => {
                this.snackbar_text = "Search Filters Saved";
                this.snackbar = true;
            });

        },

        infiniteHandler($state) {

            let id = this.id
            let purebred = this.purebred
            let pedigree_certified = this.pedigree_certified
            let dna_tested = this.dna_tested
            let distance = this.distance
            let page = this.page
            let breeds = this.breeds
            let purpose = 'lover'
            let sortBy = this.sortBy
            let size = this.size

            window.gtag('event', 'search_lovers', {
                event_category: 'Search',
                event_label: 'Search Lovers',
            });

            this.$store.dispatch('search', {
                    id,
                    purebred,
                    pedigree_certified,
                    dna_tested,
                    distance,
                    page,
                    breeds,
                    purpose,
                    sortBy,
                    size
                })
                .then((resp) => {
                    if (resp > 0) {
                        this.page += 1;
                        $state.loaded();

                        window.gtag('event', 'search_lovers_results', {
                            event_category: 'Search',
                            event_label: 'Search Lovers',
                        });


                    } else {
                        $state.complete();
                    }
                })
                .catch(err => {
                    $state.complete();
                });

        },
    }
}
</script>

<style>
.search-filter .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)>.v-input__control>.v-input__slot,
.v-text-field.v-text-field--enclosed .v-text-field__details {
    padding: 0px !important;
}
</style>
