<template>
    <div>
        <v-card style="border: 3px solid #7E0DE7; border-radius: 14px;" outlined v-if="$vuetify.breakpoint.xs">
            <v-img v-if="business.cover_media_url" :src="business.cover_media_url" cover min-height="200"
                max-height="200" class="rounded-t-lg">
                <div class="text-overline text-center white--text"
                    style="background-color:rgba(0,0,0,0.50); font-size: 10px !important; line-height: 1.5rem;">
                    sponsored</div>

            </v-img>
            <v-img v-else :src="require('@/assets/img/vet.png')" cover min-height="200"
                max-height="200" class="rounded-t-lg">
                <div class="text-overline text-center white--text"
                    style="background-color:rgba(0,0,0,0.50); font-size: 10px !important; line-height: 1.5rem;">
                    sponsored</div>

            </v-img>
            <v-card-text class="pt-0">
                <v-list>
                    <v-list-item class="pl-0">
                        <v-list-item-avatar size="54" v-if="business.logo">
                            <v-img :src="business.logo"></v-img>
                        </v-list-item-avatar>

                        <v-list-item-content>
                            <v-list-item-title class="text-subtitle-2 font-weight-semi black--text">{{
                                business.business_name }}</v-list-item-title>
                            <v-list-item-subtitle class="text-caption blue--text">{{ business.tagline
                                }}</v-list-item-subtitle>
                            <v-list-item-subtitle class="text-caption mt-1">{{ business.city }}, {{
                                business.state }}</v-list-item-subtitle>
                        </v-list-item-content>

                    </v-list-item>
                </v-list>
                <div class="text-caption mb-4 line-clamp">
                    {{ business.description }}
                </div>
                <div>
                    <v-chip v-for="(item, idx) in limitedServices" :key="idx" small outlined color="primary"
                        class="ma-1">
                        {{ item.service_name }}
                    </v-chip>
                    <v-chip v-if="remainingServices > 0" small outlined color="grey" class="ma-1">
                        +{{ remainingServices }} more
                    </v-chip>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-btn block color="primary" @click.stop="openBusinessPop">VIEW DETAILS</v-btn>
            </v-card-actions>
        </v-card>



        <v-card style="border: 3px solid #7E0DE7; border-radius: 14px;" outlined v-else>
            <v-row no-gutters>
                <v-col cols="5">
                    <v-img v-if="business.cover_media_url" :src="business.cover_media_url" cover min-height="100%"
                        class="rounded-l-lg">
                        <div class="text-overline text-center white--text"
                            style="background-color:rgba(0,0,0,0.50); font-size: 10px !important; line-height: 1.5rem;">
                            sponsored</div>
                       <!--  <v-btn v-if="$vuetify.breakpoint.mdAndDown && !$vuetify.breakpoint.sm" absolute bottom right
                            color="primary" @click.stop="openBusinessPop">CONTACT NOW</v-btn> -->
                    </v-img>
                    <v-img v-else :src="require('@/assets/img/vet.png')" cover min-height="100%"
                        class="rounded-l-lg">
                        <div class="text-overline text-center white--text"
                            style="background-color:rgba(0,0,0,0.50); font-size: 10px !important; line-height: 1.5rem;">
                            sponsored</div>
                       <!--  <v-btn v-if="$vuetify.breakpoint.mdAndDown && !$vuetify.breakpoint.sm" absolute bottom right
                            color="primary" @click.stop="openBusinessPop">CONTACT NOW</v-btn> -->
                    </v-img>


                </v-col>
                <v-col cols="7">
                    <v-card-text class="pt-0 pb-1">
                        <v-list>
                            <v-list-item class="pl-0">
                                <v-list-item-avatar size="54" v-if="business.logo">
                                    <v-img :src="business.logo"></v-img>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                    <v-list-item-title class="text-subtitle-1 font-weight-semi black--text">{{
                                        business.business_name }}</v-list-item-title>
                                    <v-list-item-subtitle class="text-caption blue--text">{{ business.tagline
                                        }}</v-list-item-subtitle>
                                    <v-list-item-subtitle class="text-caption mt-1">{{ business.city }}, {{
                                        business.state }}</v-list-item-subtitle>
                                </v-list-item-content>

                            </v-list-item>
                        </v-list>
                        <div class="text-caption mb-2 line-clamp">
                            {{ business.description }}
                        </div>
                        <div>
                            <v-chip v-for="(item, idx) in limitedServices" :key="idx" small outlined color="primary"
                                class="ma-1">
                                {{ item.service_name }}
                            </v-chip>
                            <v-chip v-if="remainingServices > 0" small outlined color="grey" class="ma-1">
                                +{{ remainingServices }} more
                            </v-chip>
                        </div>
                    </v-card-text>

                   <!--  <v-card-actions v-if="$vuetify.breakpoint.lgAndUp || $vuetify.breakpoint.sm">
                        <v-btn block color="primary" @click.stop="openBusinessPop">CONTACT NOW</v-btn>
                    </v-card-actions> -->

                    <v-card-actions>
                        <v-btn block color="primary" @click.stop="openBusinessPop">VIEW DETAILS</v-btn>
                    </v-card-actions>

                </v-col>
            </v-row>

        </v-card>

        <business-pop v-if="showBusinessPop" v-model="showBusinessPop" :business="business" />

    </div>
</template>

<script>
import BusinessPop from './BusinessPop.vue';
export default {
    name: 'BusinessCard',
    props: ['business'],
    data() {
        return {
            showBusinessPop: false,
        }
    },

    components: {
        BusinessPop
    },
    mounted() {



    },


    watch: {

    },
    computed: {


        limitedServices() {
            // Limit the services to 4 or 5
            return this.business.services.slice(0, 4);
        },
        remainingServices() {
            // Calculate the remaining services count
            return this.business.services.length - this.limitedServices.length;
        },


    },

    methods: {

        openBusinessPop() {

            this.showBusinessPop = true;
            let bxid = this.business.xid;
            let action = 'view';
            this.$store.dispatch('logBusinessAnalytics', {bxid,action});

        },



    }

}
</script>