<template>
<div class="search pa-4">
    <v-app-bar app flat color="primary" dark>

        <v-btn icon :to="'/home'" v-if="this.$store.getters.previousRoute == null">
            <v-icon>mdi-home</v-icon>
        </v-btn>
        <v-btn icon @click="$router.go(-1)" v-else>
            <v-icon>mdi-arrow-left</v-icon>
        </v-btn>

        <v-toolbar-title class="text-subtitle-1 font-weight-medium">Buyers for {{pet.name ? pet.name : ''}}</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon  @click="filterDialog = true">
            <v-icon>$vuetify.icons.filter-icon</v-icon>
        </v-btn>

        <template v-slot:extension>
            <div @click="filterDialog = true">
                <v-chip class="ma-1" small color="#f0e7ff" text-color="primary">
                    Within {{distance}} miles
                </v-chip>
                <v-chip class="ma-1" small color="#f0e7ff" text-color="primary" v-if="sortBy">
                    {{ sortBy == 'distance' ? 'Sort by Nearest First' : ''}}
                    {{ sortBy == 'last_activity' ? 'Sort by Last Activity' : ''}}
                </v-chip>
            </div>

        </template>

    </v-app-bar>

    <!-- <v-row>
        <v-col cols="12" sm="6" md="4" lg="3" xl="2" v-for="(item,index) in this.$store.getters.searchResults" :key="index" class="px-4 pb-2">
            <adopter-card :adopter="item"></adopter-card>
        </v-col>
    </v-row> -->

    <v-row>
  
        <template v-for="(item, index) in this.$store.getters.searchResults">
        <!-- 1) Pet card column, with a unique key -->
        <v-col
            :key="'adopter-' + index"
            cols="12"
            sm="6"
            md="4"
            lg="3"
            xl="2"
            class="px-4 pb-2"
        >
            <adopter-card :adopter="item"></adopter-card>
        </v-col>

        </template>

    </v-row>

    <infinite-loading :identifier="infiniteId" @infinite="infiniteHandler">

        
        <div slot="no-more">
            <v-row class="pt-6" justify="center">
                <v-col cols="12" md="6" class="py-12 px-6 text-center">

                    <div class="text-center">
                            <p class="pt-4 text-subtitle-2">Couldn't find a proper buyer? 🙁</p>
                            <!-- <v-icon size="80" color="#eee" class="pb-4">mdi-emoticon-sad-outline</v-icon> -->
                            <p class="pb-2 text-caption">You may try changing the <a href="javascript:void(0);" @click="filterDialog = true">filters</a>.</p>

                            <v-alert icon="mdi-email-receive-outline" prominent dark color="primary" >
                                <span class="text-caption">We will send more buyers based on your filters to your inbox as we find them.</span>
                            </v-alert>
                        </div>

                        <div class="text-center">
                        
                        <v-card class="mild-shadow mt-12">
                        <v-card-text class="pa-6">
                        
                        <div class="text-h6 font-weight-bold pb-4 black--text">
                            Install Petmeetly
                        </div>
                        <div class="text-caption pb-4">
                            Save Petmeetly on your device for quick and easy access when you're on the go.
                        </div>

                        <v-row>
                            <v-col cols="12"  sm="6">
                                <a href="https://apps.apple.com/app/apple-store/id6478047015?pt=118572154&ct=Web%20App%20Referral&mt=8" target="_blank">
                                <v-img src='@/assets/logo/appstore-256x256.png' max-width="180"  class="mx-auto"></v-img>
                            </a>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <a href="https://play.google.com/store/apps/details?id=com.petmeetly" target="_blank">
                                <v-img src='@/assets/logo/googleplay-badge-256.png' max-width="200" class="mx-auto"></v-img>
                                </a>
                            </v-col>
                        </v-row>


                        </v-card-text>

                        </v-card>

                    </div>

                </v-col>
            </v-row>

        </div>
        <div slot="no-results">
            <v-row class="pt-6" align="center" justify="center">
                <v-col cols="12" md="6" class="pa-12 text-center">
                    <v-icon size="120" color="#eee">mdi-heart-broken</v-icon>
                    <div class="pt-4">
                        <p>We're sorry. We couldn't find a proper buyer {{pet.name ? 'for '+pet.name : ''}} now.</p>
                        <p>Watch your inbox. We will let you know when we find a few matching buyer.</p>
                        <p>You may also try changing the <a href="javascript:void(0);" @click="filterDialog = true">filters</a>.</p>
                    </div>
                </v-col>
            </v-row>
        </div>
        <div slot="spinner">
            <v-row>
                <v-col cols="12" justify="center" align="center" class="mt-5">
                    <!-- <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular> -->
                    <img :src="$loaderImageUrl" height="100" alt="Loading..." />
                </v-col>

            </v-row>
        </div>

    </infinite-loading>
    <!-- </div> -->
    <v-snackbar v-model="snackbar" bottom>
        {{ snackbar_text }}
        <template v-slot:action="{ attrs }">
            <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>

    <v-dialog v-model="filterDialog" :fullscreen="$vuetify.breakpoint.xsOnly" scrollable origin="top right" max-width="450" transition="dialog-transition">
        <v-card>
            <div>
                <v-toolbar flat>
                    <v-toolbar-title class="toolbar-title">
                        <span class="subtitle-custom"></span>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn icon @click.native="filterDialog=false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
            </div>

            <v-card-text class="pt-10 search-filter">

                <v-row>

                    <v-col cols="12" class="px-4 py-0">
                        <div class="primary--text pb-2 text-subtitle-2">Buyers within a distance of <span class="font-weight-bold">{{distance}}</span> Miles</div>
                        <v-slider thumb-label v-model="distance" step="10" min="10" max="3000" hide-details color="primary" track-color="#ddd" track-fill-color="primary"></v-slider>
                    </v-col>

                    <v-col cols="12" class="px-4 py-2 pt-8">
                        <div class="primary--text text-subtitle-2 font-weight-600">Sort by</div>
                        <v-radio-group v-model="sortBy" class="mt-2">
                            <v-radio label="Default" value=""></v-radio>
                            <v-radio label="Last Activity" value="last_activity"></v-radio>
                            <v-radio label="Distance" value="distance"></v-radio>
                        </v-radio-group>
                    </v-col>

                </v-row>

            </v-card-text>

            <v-card-actions>

                <v-spacer></v-spacer>

                <v-btn block large color="primary" @click="resetAndFilter">Search</v-btn>

                <v-spacer></v-spacer>

            </v-card-actions>

        </v-card>
    </v-dialog>

</div>
</template>

<script>
import AdopterCard from '../components/AdopterCard.vue'
import InfiniteLoading from 'vue-infinite-loading'
export default {
    name: 'SearchBuyers',
    props: ['id'],

    mounted() {
        //console.log('mounting search')

        this.$store.dispatch('setMyPetID', this.id).then(() => {
            //set filter criterias
            //this.searching = true;

            let pet = this.$store.getters.myPetDetails(this.$store.getters.myPetID);

            if (pet) {
                this.pet = pet;

                this.distance = pet.search_filters.distance
                this.sortBy = pet.search_filters.sort_by

                this.$store.dispatch('clearSearchResults');
            } else {
                //Incase we cannot get the pet
                this.$router.push('/home');
            }

            //Show premium pop
            if (!this.$store.getters.isPremium && this.$store.getters.userDetails.name != "") {

                let today =  this.$moment()
                let lastPrompt = this.$moment((this.$cookie.get("pm_premium_prompt_last_seen") ? this.$cookie.get("pm_premium_prompt_last_seen") : "1/1/2022"),'M/D/YYYY')
                let days = today.diff(lastPrompt, 'days')

                if(days > 0)
                {
                    setTimeout(() => {
                        this.$store.dispatch('showPremiumPop', true);
                    }, 5000);
                    //this.$store.dispatch('showPremiumPop', true);
                }
            }
            
        });
    },

    components: {
        AdopterCard,
        InfiniteLoading
    },

    data() {
        return {
            snackbar: false,
            snackbar_text: '',
            pet: {
                pet_type: {
                    xid: ''
                }
            },
            favLoading: false,
            page: 1,
            infiniteId: +new Date(),
            filterDialog: false,
            distance: 300,
            dataLoading: false,
            sortBy: '',
            
        }
    },



    methods: {


        resetAndFilter() {

            this.filterDialog = false;
            this.page = 1;
            this.$store.dispatch('clearSearchResults');
            this.infiniteId += 1;

            let id = this.id
            let distance = this.distance
            let sort_by = this.sortBy

            this.$store.dispatch('setPetFilters', {
                id,
                distance,
                sort_by
            }).then(() => {
                this.snackbar_text = "Search Filters Saved";
                this.snackbar = true;
            });

        },

        infiniteHandler($state) {

            let id = this.id
            let distance = this.distance
            let page = this.page
            let sortBy = this.sortBy

            window.gtag('event', 'search_buyers', {
                event_category: 'Search',
                event_label: 'Search Buyers',
            });

            this.$store.dispatch('searchBuyers', {
                    id,
                    distance,
                    page,
                    sortBy
                })
                .then((resp) => {
                    if (resp > 0) {
                        this.page += 1;
                        $state.loaded();

                        window.gtag('event', 'search_buyers_results', {
                            event_category: 'Search',
                            event_label: 'Search Buyers',
                        });


                    } else {
                        $state.complete();
                    }
                })
                .catch(err => {
                    $state.complete();
                });

        },
    }
}
</script>


